<template>
    <AdminPagosComponent />
</template>

<script>
import AdminPagosComponent from '../../components/admin/AdminPagosComponent.vue'

export default {
    name: 'AdminPagosView',

    components: {
        AdminPagosComponent,
    },

}
</script>