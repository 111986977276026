export default class PagoRequest {
    constructor(user, tipo_suscripcion, fecha_inicio, metodo_pago, payed, referenciaPago, cantidad) {
        this.user = user
        this.tipo_suscripcion = tipo_suscripcion;
        this.fecha_inicio = fecha_inicio;
        this.metodo_pago = metodo_pago;
        this.payed = payed;
        this.referenciaPago = referenciaPago
        this.cantidad = cantidad
    }
}