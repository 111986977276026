<template>
  <OrdersComponent />
</template>
  
<script>
import OrdersComponent from '../../components/user/OrdersComponent.vue'

export default {
  name: "OrdersView",
  components: {
    OrdersComponent,
  },

};
</script>
  
<style lang="scss" scoped></style>
  