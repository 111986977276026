<template>
    <v-container>
        <v-card elevation="2" outlined shaped>
            <br>
            <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                <v-col cols="2" xs="0" sm="0" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn size="large" @click="toUsers()">Users</v-btn>
                    
                </v-col>
                <v-col cols="2" xs="1" sm="1" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn @click="toComerciales()">Comercial</v-btn>
                </v-col>
                <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toUsers()">Users</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toComerciales()">Comerciales</v-btn>
                </v-col> -->
            </v-row>
            <br>
            <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                <v-col cols="2" xs="0" sm="0" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn @click="toSubs()">Suscripcion</v-btn>
                </v-col>
                <v-col cols="2" xs="1" sm="1" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn size="large" @click="toPagos()">Pagos</v-btn>
                </v-col>
                <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toSubs()">Suscripciones</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toPagos()">Pagos</v-btn>
                </v-col> -->
            </v-row>
            <br>
            <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                <v-col cols="2" xs="0" sm="0" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn size="large" @click="toBots()">Bots</v-btn>
                </v-col>
                <v-col cols="2" xs="1" sm="1" md="2"></v-col>
                <v-col cols="3" xs="5" sm="5" md="3">
                    <v-btn size="large" @click="toTickets()">Tickets</v-btn>
                </v-col>
                <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toBots()">Bots</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn size="large" @click="toTickets()">Tickets</v-btn>
                </v-col> -->
            </v-row>
            <br>
            <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                <v-col cols="4" xs="3" sm="3" md="4"></v-col>
                <v-col cols="4" xs="6" sm="6" md="4">
                    <v-btn size="large" @click="logOut()">Salir</v-btn>
                </v-col>
                <!-- <v-col cols="4" class="flex-grow-0 flex-shrink-0"></v-col>
                <v-col cols="4">
                    <v-btn size="large" @click="logOut()">Salir</v-btn>
                </v-col> -->
            </v-row>
            <br>
        </v-card>

    </v-container>
</template>

<script>


export default {
    name: 'AdminMenu',
    methods: {
        toUsers() {
            const path = '/admin_users'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toComerciales() {
            const path = '/admin_comerciales'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toBots() {
            const path = '/admin_bots'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toSubs() {
            const path = '/admin_subs'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toPagos() {
            const path = '/admin_pagos'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toTickets() {
            const path = '/admin_tickets'
            if (this.$route.path !== path) this.$router.push(path)
        },
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
        },
    },
}
</script>