<template>
  <IndexComponent />
</template>
    
<script>
import IndexComponent from '../../components/public/IndexComponent.vue'

export default {
  name: 'IndexView',
  components: {
    IndexComponent,
  },
}
</script>