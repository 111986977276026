<template>
    <AdminSubsComponent/>
  </template>
  
  <script>
  import AdminSubsComponent from '../../components/admin/AdminSubsComponent.vue'
  
  export default {
    name: 'AdminSubsView',
  
    components: {
        AdminSubsComponent,
    },
    
  }
  </script>