<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet v-for="n in 1" :key="n" class="ma-2 pa-2">
                <v-card elevation="2" outlined shaped>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <AdminMenu />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <v-card-title>{{ title }}</v-card-title>
                                <v-divider class="mx-4"></v-divider>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2"></v-col>
                        <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                            <v-card elevation="2" class="pa-4" outlined>
                                <v-list dense nav>
                                    <v-list-item v-for="item in items" :key="item.title"
                                        @click="menuActionClick(item.action)">
                                        <v-list-item-icon>
                                            <v-icon>{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col> -->
                        <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                            <v-row>
                                <v-col cols="3"></v-col>
                                <v-col cols="6">
                                    <v-card-title>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"
                                            single-line hide-details></v-text-field>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3"></v-col>
                                <!-- <v-col cols="3">
                                    <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                        <v-card-title>
                                            <v-list dense nav>
                                                <v-list-item v-for="item in items" :key="item.title"
                                                    @click="menuActionClick(item.action)">
                                                    <v-list-item-icon>
                                                        <v-icon>{{ item.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card-title>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                            <v-data-table :headers="headers" :items="desserts" :search="search">
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon small class="mr-2" @click="handleClick(item, 'orders')">mdi-play</v-icon>
                                    <v-icon small class="mr-2"
                                        @click="handleClick(item, 'update')">mdi-content-save-edit-outline</v-icon>
                                    <v-icon small class="mr-2"
                                        @click="handleClick(item, 'delete')">mdi-trash-can-outline</v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <br>
                    <v-divider class="mx-4" horizontal></v-divider>
                    <br>
                    <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                        <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                        <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                            <v-alert prominent :type="types">
                                <v-row>
                                    <v-col class="grow">
                                        {{ msgError }}
                                    </v-col>
                                    <v-col class="shrink">
                                        <v-btn @click="closeAlert">OK</v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import AdminMenu from "../../components/admin/AdminMenuComponent.vue";
import UserService from '../../services/user.service';
import Bot from "../../models/bot";

export default {
    name: 'AdminBotsComponent',
    components: {
        AdminMenu
    },
    data() {
        return {
            title: 'BOTS',
            // Variables
            bot: new Bot("", "", "", ""),
            content: "",
            right: null,
            loading: false,
            message: "",
            valid: '',
            // Table
            headers: [
                { text: "Usuario", value: "user.email", align: "center" },
                { text: "Par", value: "pair", align: "center" },
                // { text: "Quantity", value: "quantity", align: "center" },
                // { text: "Amount", value: "amount", align: "center" },
                { text: "Iniciado", value: "started", align: "center" },
                { text: "Activo", value: "active", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            desserts: [],
            items: [
                {
                    title: "Crear Bot",
                    icon: "mdi-view-dashboard",
                    action: "openDialogBot"
                }
            ],
            search: '',
            isShow: false,
            msgError: '',
            apiError: false,
            types: "success"
        };
    },
    computed: {},
    created() {
    },
    mounted() {
        UserService.getBots().then(
            response => {
                if (response.data !== '') {
                    this.desserts = response.data
                    // console.log(this.desserts)
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    EventBus.dispatch("logout");
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        menuActionClick(action) {
            if (action === "openDialogBot") {
                //this.$router.push('/newbot');
                // if (this.apiError === false) {
                //     UserService.isAccount(this.$store.state.auth.user.email).then(
                //         response => {
                //             this.content = response.data;
                //             this.$router.push('/newbot');
                //         },
                //         error => {
                //             this.content =
                //                 (error.response &&
                //                     error.response.data &&
                //                     error.response.data.message) ||
                //                 error.message ||
                //                 error.toString();
                //         }
                //     );
                // } else {
                //     this.msgError = 'Verifique su API.'
                //     this.isShow = true
                // }
            }
        },
        handleClick(value, action) {
            if (this.apiError === false) {
                switch (action) {
                    case 'orders':
                        // localStorage.bot = JSON.stringify(value.id);
                        this.$router.push('/admin_trades/' + value.id);
                        break;
                    case 'update':
                        console.log('update bot')
                        break;
                    case 'delete':
                        this.bot = value
                        if (this.bot.active) {
                            console.log('delete bot')
                            UserService.deleteBot(this.bot).then(
                                response => {
                                    this.content = response.data;
                                    window.location.reload()
                                },
                                error => {
                                    this.content =
                                        (error.response &&
                                            error.response.data &&
                                            error.response.data.message) ||
                                        error.message ||
                                        error.toString();
                                }
                            );
                        }
                        break;
                }
            } else {
                this.msgError = 'Verifique su API.'
                this.isShow = true
            }
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>