import axios from "axios";
import router from '../router'
import store from '../store'
import config from './direction';

const instance = axios.create({
  
  baseURL: config.URL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  }
});

// const agent = new https.Agent({  
//   rejectUnauthorized: false
// });

instance.interceptors.response.use(function (response) {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch("auth/logout")
    if(store.state.auth.user) {
      router.push('/').catch(err => { err })
    }
    
    // router.push('/')
  } else {
    // console.log("Error")
  }
  return Promise.reject(error)
})

export default instance;