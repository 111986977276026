<template>
    <TronComponent />
  </template>
    
  <script>
  import TronComponent from '../../components/utils/TronPaymentComponent.vue'
  
  export default {
    name: "TronView",
    components: {
        TronComponent,
    },
  
  };
  </script>
    
  <style lang="scss" scoped></style>