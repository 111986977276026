<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="5" xs="0" sm="0" md="5">
                            </v-col>
                            <v-col cols="2" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <v-btn color="success" @click="menuActionClick('openDialogBot')">
                                        Abrir Ticket
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-data-table :headers="headers" :items="desserts" :search="search">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'tickets')">mdi-play</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent :type="types">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Ticket from "../../models/ticket";

export default {
    name: 'TicketComponent',
    components: {
    },
    data() {
        return {
            title: 'TICKETS',
            // Variables
            ticket: new Ticket("", "", "", ""),
            content: "",
            right: null,
            loading: false,
            message: "",
            valid: '',
            // Table
            headers: [
                { text: "Titulo", value: "titulo", align: "center" },
                { text: "Estado", value: "estado", align: "center" },
                { text: 'Actions', value: 'actions', sortable: false, align: "center" }
            ],
            desserts: [],
            search: '',
            isShow: false,
            msgError: '',
            apiError: false,
            types: "success"
        };
    },
    computed: {},
    created() {
    },
    mounted() {
        UserService.getTickets(this.$store.state.auth.user.email).then(
            response => {
                if (response.data !== '') {
                    this.desserts = response.data;
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        menuActionClick(action) {
            if (action === "openDialogBot") {
                this.$router.push('/ticket_save');
            }
        },
        handleClick(value, action) {
            switch (action) {
                case 'tickets':
                    localStorage.ticket = JSON.stringify(value.id);
                    this.$router.push('/ticket_save');
                    break;
                case 'update':
                    console.log('update bot')
                    break;
            }
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>