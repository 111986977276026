<template>
    <WalletsComponent />
  </template>
    
  <script>
  import WalletsComponent from '../../components/user/WalletsComponent.vue'
  
  export default {
    name: "WalletsView",
    components: {
        WalletsComponent,
    },
  
  };
  </script>
    
  <style lang="scss" scoped></style>
    