import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import setupInterceptors from './services/setupInterceptors';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);
setupInterceptors(store);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  theme: { dark: true },
  render: h => h(App)
}).$mount('#app')

Vue.use(Vuetify)
