<template>
    <AdminUsersComponent/>
  </template>
  
  <script>
  import AdminUsersComponent from '../../components/admin/AdminUsersComponent.vue'
  
  export default {
    name: 'AdminUsersView',
  
    components: {
        AdminUsersComponent,
    },
    
  }
  </script>