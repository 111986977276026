<template>
    <TermsComponent />
  </template>
    
  <script>
  import TermsComponent from '../../components/public/TermsComponent.vue'
  
  export default {
    name: 'TermsView',
    components: {
        TermsComponent,
    },
  }
  </script>