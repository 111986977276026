<template>
    <TicketSaveComponent />
  </template>
    
  <script>
  import TicketSaveComponent from '../../components/user/TicketSaveComponent.vue'
  
  export default {
    name: "TicketView",
    components: {
        TicketSaveComponent,
    },
  
  };
  </script>
    
  <style lang="scss" scoped></style>