<template>
    <v-container class="bg-surface-variant">
        <v-row no-gutters style="flex-wrap: nowrap;">
            <v-col cols="12" @click="goToIndex">
                <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
                <v-img :src="require('../../assets/logo.jpg')" class="my-3" contain height="200" />
            </v-col>
        </v-row>
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">VERIFICA TU USUARIO</v-card-title>
                        </v-row>

                    </v-card>
                </v-col>
            </v-row>
            <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                <v-col cols="10" xs="8" sm="8" md="4">
                    <v-alert prominent :type="types">
                        <v-row>
                            <v-col class="grow">
                                {{ message }}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn @click="closeAlert">OK</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import UserService from '../../services/user.service';

export default {
    name: 'VerifyComponent',
    data() {
        return {
            isShow: false,
            message: '',
            types: "success",
        };
    },
    mounted() {
        UserService.setVerify(this.$route.params.verify).then(
            response => {
                // console.log(response.data.message)
                this.session = response.data
                this.isShow = true
                this.message = response.data.message
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                // console.log(error)
                this.isShow = true
                this.types = 'error'
                this.message = error
            }
        );
    },
    computed: {

    },
    created() { },
    methods: {
        goToIndex() {
            this.$router.push('/');
        },
        closeAlert() {
            this.isShow = false
            this.goToIndex()
        }
    }
}
</script>