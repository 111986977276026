<template>
    <AmazonCheckoutComponent />
</template>
      
<script>
import AmazonCheckoutComponent from '../../components/amazon/AmazonCheckoutComponent.vue'

export default {
    name: 'AmazonCheckoutView',
    components: {
        AmazonCheckoutComponent,
    },
}
</script>