export default class Sub {
    constructor(id, user, quantity, start_time, end_time, payed, chargeID, chargePermissionID, metodo) {
        this.id = id
        this.user = user
        this.quantity = quantity;
        this.start_time = start_time;
        this.end_time = end_time;
        this.payed = payed;
        this.chargeID = chargeID
        this.chargePermissionID = chargePermissionID
        this.metodo = metodo
    }
}
