<template>
    <SuscripcionMenuComponent />
  </template>
    
  <script>
  import SuscripcionMenuComponent from '../../components/user/SuscripcionMenuComponent.vue'
  
  export default {
    name: "SuscripcionMenuView",
    components: {
        SuscripcionMenuComponent,
    },
  
  };
  </script>