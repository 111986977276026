<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" >
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card ref="cardTitle" title="Elige metodo de pago." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">{{ tituloDialog }}</strong>
                                        </p>
                                        <br>
                                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                                            <v-col cols="1" xs="1" sm="1" md="1"></v-col>
                                            <v-col cols="10" xs="8" sm="8" md="10">
                                                <v-alert prominent :type="types">
                                                    <v-row>
                                                        <v-col class="grow">
                                                            {{ msgError }}
                                                        </v-col>
                                                        <v-col class="shrink">
                                                            <v-btn @click="closeModal">OK</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>


export default {
    name: 'SelectModalidadComponent',
    data: function () {
        return {
            component: '',
            showModal: true,
            dialog: false,
            isShow: false,
            msgError: '',
            types: "success",
            tituloDialog: '',

            item: '',
            idSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },
        closeModal() {
            if(this.component === 'LOGIN'){
                this.$emit('login', 'closeAlert');
            }
            this.dialog = false
        },

    },
    mounted() {

    }
}
</script>