<template>
    <AmazonResultComponent />
</template>
      
<script>
import AmazonResultComponent from '../../components/amazon/AmazonResultComponent.vue'

export default {
    name: 'AmazonResultView',
    components: {
        AmazonResultComponent,
    },
}
</script>