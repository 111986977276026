export default class TipoSuscripcion {
    constructor(id, nombre, descripcion, limiteInversionPorBot, dias, precio) {
        this.id = id;
        this.nombre = nombre;
        this.descripcion = descripcion;
        this.limiteInversionPorBot = limiteInversionPorBot;
        this.dias = dias
        this.precio = precio;
    }
}
