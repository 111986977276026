<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col cols="12" @click="goToIndex">
                    <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
                    <v-img :src="require('../../assets/logo.png')" class="my-3" contain height="200" />
                </v-col>
            </v-row>
            <div class="d-flex flex-column mb-6 bg-surface-variant">
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col>
                        <v-card elevation="2" outlined shaped>
                            <v-row justify="center">
                                <v-card-title class="text-h5">REGISTRO</v-card-title>
                            </v-row>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="3" xs="0" sm="0" md="3" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="6" xs="8" sm="8" md="6" class="flex-grow-0 flex-shrink-0">
                                            <validation-provider v-slot="{ errors }" name="Nombre"
                                                rules="required|max:10">
                                                <v-text-field v-model="name" :counter="10" :error-messages="errors"
                                                    label="Nombre" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="Apellidos"
                                                rules="required|max:20">
                                                <v-text-field v-model="lastName" :counter="20" :error-messages="errors"
                                                    label="Apellidos" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="email"
                                                rules="required|email">
                                                <v-text-field v-model="email" :error-messages="errors" label="E-mail"
                                                    required></v-text-field>
                                            </validation-provider>
                                            <v-text-field v-model="referido" :error-messages="errors"
                                                label="Codigo de Referido" required></v-text-field>
                                            <v-card elevation="8" outlined shaped>
                                                <v-row>
                                                    <v-card-text>
                                                        - Debe contener una mayuscula.<br>
                                                        - Debe contener una minuscula.<br>
                                                        - Debe contener un caracter especial.
                                                    </v-card-text>
                                                </v-row>
                                            </v-card>
                                            <validation-provider vid="passwd" v-slot="{ errors }" name="password"
                                                rules="required|pass">
                                                <v-text-field :type="show4 ? 'text' : 'password'" v-model="password"
                                                    :error-messages="errors" label="Password" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="confPassword"
                                                rules="required|confirmed:@passwd">
                                                <v-text-field type="password" v-model="password1"
                                                    :error-messages="errors" label="Confirme Password"
                                                    required></v-text-field>
                                            </validation-provider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="5" xs="0" sm="0" md="5">
                                        </v-col>
                                        <v-col cols="2" xs="2" sm="2" md="2">
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success" @click="handleRegister">
                                                    GUARDAR
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" md="1" lg="5">
                                        </v-col>
                                        <v-col cols="10" xs="6" sm="6" md="2">
                                            <v-row no-gutters justify="center">
                                                <p>¿Ya tienes cuenta?</p>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" md="1" lg="5">
                                        </v-col>
                                        <v-col cols="10" xs="6" sm="6" md="2">
                                            <v-row no-gutters justify="center">
                                                <router-link to="/login">Login</router-link>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                    <v-col  cols="1" xs="1" sm="1" md="4"></v-col>
                    <v-col cols="10" xs="8" sm="8" md="4">
                        <v-alert prominent :type="types">
                            <v-row>
                                <v-col class="grow">
                                    {{ msgError }}
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="closeDialog">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
import User from '../../models/user';
// import UserService from '../../services/user.service';
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

extend("pass", {
    params: ["otherValue"],
    validate: (value) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).+$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no cumple los requisitos."
});

extend("confirmed", {
    params: ['otherValue'],
    validate: (value, { otherValue }) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).+$/.test(value) ||
            value !== otherValue
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no coincide."
});

export default {
    name: 'RegisterComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            user: new User('', '', ''),
            name: '',
            lastName: '',
            email: '',
            referido: '',
            param_refer: '',
            password: '',
            show4: false,
            show5: false,
            password1: '',
            submitted: false,
            successful: false,
            message: '',
            msgError: '',
            isShow: false,
            types: "success",
            success: false,
            errors: ''
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/home');
        }
        if(this.$route.params.referido !== null){
            this.referido = this.$route.params.referido
        }
    },
    methods: {
        validate() {
            console.log(this.$refs.form.validate())
        },
        // handleRegister() {
        //     this.message = '';
        //     this.submitted = true;
        //     this.$refs.observer.validate().then(success => {
        //         if (!success) {
        //             return;
        //         }
        //         // Resetting Values
        //         // this.firstName = this.lastName = this.email = '';

        //         // Wait until the models are updated in the UI
        //         this.$nextTick(() => {
        //             try {
        //                 this.user.name = this.name
        //                 this.user.lastName = this.lastName
        //                 this.user.email = this.email
        //                 this.user.cod_refer = this.referido
        //                 this.user.password = this.password
        //                 UserService.register(this.user).then(
        //                     response => {
        //                         // console.log(response)
        //                         if (response.status === 200) {
        //                             this.msgError = response.data.message;
        //                             this.types = 'success'
        //                             this.successful = true;
        //                             this.isShow = true
        //                         } else {
        //                             // console.log(response.response.data.message)
        //                             this.msgError = response.response.data.message;
        //                             this.types = 'error'
        //                             this.successful = false;
        //                             this.isShow = true
        //                         }
        //                     },
        //                     error => {
        //                         this.message =
        //                             (error.response && error.response.data && error.response.data.message) ||
        //                             error.message ||
        //                             error.toString();
        //                         console.log(error.response)
        //                         this.error = true
        //                         this.msgError = error
        //                         this.types = 'error'
        //                         this.isShow = true
        //                         this.successful = false;
        //                     }
        //                 );
        //             } catch (error) {
        //                 console.log(error)
        //                 this.error = true
        //                 this.msgError = error
        //                 this.types = 'error'
        //                 this.isShow = true
        //             }
        //         });
        //     });
        // },
        goToIndex() {
            this.$router.push('/');
        },
        clickDialog() {
            if (this.successful) {
                this.isShow = false
                this.$router.push('/login');
            } else {
                this.isShow = false
            }
        },
        closeDialog() {
            if (this.successful) {
                this.isShow = false
                this.$router.push('/login');
            } else {
                this.isShow = false
            }
        }
    }
}
</script>

<style scoped>
.login {
    padding: 2rem;
}

.title {
    text-align: center;
}

.form {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    min-width: 350px;
    max-width: 100%;
    background: rgba(19, 35, 47, 0.9);
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}


label {
    display: block;
    margin-top: 10px;
}


p {
    line-height: 1rem;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>