<template>
  <ProfileComponent/>
</template>

<script>
import ProfileComponent from '../../components/user/ProfileComponent.vue'

export default {
  name: 'ProfileView',
  components: {
    ProfileComponent,
  },
  
};
</script>