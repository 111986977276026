<template>
    <SuscripcionComponent />
  </template>
    
  <script>
  import SuscripcionComponent from '../../components/user/SuscripcionComponent.vue'
  
  export default {
    name: "SuscripcionView",
    components: {
        SuscripcionComponent,
    },
  
  };
  </script>
    
  <style lang="scss" scoped></style>