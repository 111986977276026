<template>
  <AdminHomeComponent />
</template>

<script>
import AdminHomeComponent from '../../components/admin/AdminHomeComponent.vue'

export default {
  name: 'AdminHomeView',

  components: {
    AdminHomeComponent,
  },

}
</script>