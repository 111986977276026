<template>
    <AdminUserNewComponent />
</template>

<script>
import AdminUserNewComponent from '../../components/admin/AdminUserNewComponent.vue'

export default {
    name: 'AdminUserNewView',

    components: {
        AdminUserNewComponent,
    },

}
</script>