<template>
    <AdminComercialesComponent/>
  </template>
  
  <script>
  import AdminComercialesComponent from '../../components/admin/AdminComercialesComponent.vue'
  
  export default {
    name: 'AdminHomeView',
  
    components: {
        AdminComercialesComponent,
    },
    
  }
  </script>