<template>
    <AdminUserComponent/>
  </template>
  
  <script>
  import AdminUserComponent from '../../components/admin/AdminUserComponent.vue'
  
  export default {
    name: 'AdminUserView',
  
    components: {
        AdminUserComponent,
    },
    
  }
  </script>