<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet v-for="n in 1" :key="n" class="ma-2 pa-2">
                <v-card elevation="2" outlined shaped>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <AdminMenu />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <v-card-title>{{ title }}</v-card-title>
                                <v-divider class="mx-4"></v-divider>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2"></v-col>
                        <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                            <v-card elevation="2" class="pa-4" outlined>
                                <v-list dense nav>
                                    <v-list-item v-for="item in items" :key="item.title"
                                        @click="menuActionClick(item.action)">
                                        <v-list-item-icon>
                                            <v-icon>{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col> -->
                        <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                            <v-row>
                                <v-col cols="6">
                                    <v-card-title>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"
                                            single-line hide-details></v-text-field>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3"></v-col>
                                <v-col cols="3">
                                    <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                        <v-card-title>
                                            <v-list dense nav>
                                                <v-list-item v-for="item in items" :key="item.title"
                                                    @click="menuActionClick(item.action)">
                                                    <v-list-item-icon>
                                                        <v-icon>{{ item.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-data-table :headers="headers" :items="desserts" :search="search">
                                <template v-slot:[`item.actions`]="{ item }">
                                    <!-- <v-icon small class="mr-2" @click="handleClick(item, 'orders')">mdi-play</v-icon> -->
                                    <v-icon small class="mr-2"
                                        @click="handleClick(item, 'update')">mdi-content-save-edit-outline</v-icon>
                                    <v-icon small class="mr-2"
                                        @click="handleClick(item, 'delete')">mdi-trash-can-outline</v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <br>
                    <v-divider class="mx-4" horizontal></v-divider>
                    <br>
                    <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                        <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                        <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                            <v-alert prominent :type="types">
                                <v-row>
                                    <v-col class="grow">
                                        {{ msgError }}
                                    </v-col>
                                    <v-col class="shrink">
                                        <v-btn @click="closeAlert">OK</v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
// import EventBus from "../common/EventBus";
import AdminMenu from "../../components/admin/AdminMenuComponent.vue";
import UserService from '../../services/user.service';
// import Bot from "../models/bot";

export default {
    name: 'AdminUsersComponent',
    components: {
        AdminMenu
    },
    data() {
        return {
            title: 'USUARIOS',
            // Variables
            // bot: new Bot("", "", "", ""),
            content: "",
            right: null,
            loading: false,
            message: "",
            valid: '',
            // Table
            headers: [
                { text: "Nombre", value: "name", align: "center" },
                { text: "Apellidos", value: "lastName", align: "center" },
                { text: "Email", value: "email", align: "center" },
                { text: "Activado", value: "enabled", align: "center" },
                // { text: "Alta", value: "percent", align: "center" },
                { text: 'Actions', value: 'actions', sortable: false, align: "center" }
            ],
            desserts: [],
            items: [
                {
                    title: "Crear Usuario",
                    icon: "mdi-view-dashboard",
                    action: "newUser"
                }
            ],
            search: '',
            isShow: false,
            msgError: '',
            apiError: false,
            types: "success"
        };
    },
    computed: {},
    created() {
        UserService.getUsers().then(
            response => {
                if (response.data !== '') {
                    this.desserts = response.data;
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.msgError = error.response.data
                this.isShow = true
                this.apiError = true
                this.types = 'error'
            }
        );
    },
    mounted() {
        // UserService.getListBots(this.$store.state.auth.user.email).then(
        //     response => {
        //         if (response.data !== '') {
        //             this.desserts = response.data;
        //         }
        //     },
        //     error => {
        //         this.content =
        //             (error.response &&
        //                 error.response.data &&
        //                 error.response.data.message) ||
        //             error.message ||
        //             error.toString();

        //         if (error.response && error.response.status === 403) {
        //             EventBus.dispatch("logout");
        //         }
        //     }
        // );
        // UserService.getListSymbols().then(
        //     response => {
        //         this.itemsSymbols = response.data;
        //     },
        //     error => {
        //         this.content =
        //             (error.response &&
        //                 error.response.data &&
        //                 error.response.data.message) ||
        //             error.message ||
        //             error.toString();
        //     }
        // );
    },
    methods: {
        menuActionClick(action) {
            if (action === "newUser") {
                this.$router.push('/admin_user_new');
                // if (this.apiError === false) {
                //     UserService.isAccount(this.$store.state.auth.user.email).then(
                //         response => {
                //             this.content = response.data;
                //             this.$router.push('/newbot');
                //         },
                //         error => {
                //             this.content =
                //                 (error.response &&
                //                     error.response.data &&
                //                     error.response.data.message) ||
                //                 error.message ||
                //                 error.toString();
                //         }
                //     );
                // } else {
                //     this.msgError = 'Verifique su API.'
                //     this.isShow = true
                // }
            }
        },
        handleClick(value, action) {
            switch (action) {
                case 'update':
                    // localStorage.adminUser = JSON.stringify(value.id);
                    // console.log('update user')
                    this.$router.push("/admin_user/" + value.id);
                    break;
                case 'delete':
                    console.log('delete user')
                    // this.bot = value
                    console.log(this.bot)
                    // UserService.deleteBot(this.bot).then(
                    //         response => {
                    //             this.content = response.data;
                    //             this.$router.push('/newbot');
                    //         },
                    //         error => {
                    //             this.content =
                    //                 (error.response &&
                    //                     error.response.data &&
                    //                     error.response.data.message) ||
                    //                 error.message ||
                    //                 error.toString();
                    //         }
                    //     );
                    break;
            }
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>