<template>
  <RegisterComponent />
</template>

<script>
import RegisterComponent from '../../components/public/RegisterComponent.vue'

export default {
  name: 'RegisterView',
  components: {
    RegisterComponent,
  },
};
</script>

