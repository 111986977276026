export default class Type {
    constructor(id, name, nivel, limite, bots, meses, precio) {
        this.id = id;
        this.name = name;
        this.nivel = nivel;
        this.limite = limite;
        this.bots = bots;
        this.meses = meses;
        this.precio = precio;
    }

}
