<template>
    <div>
        <v-btn color="#5ceabb" class="w-auto ma-2 black--text" @click="goLogin">
            <div class="d-none d-lg-block">
                Login
            </div>
            <v-icon right dark>
                mdi-login
            </v-icon>
        </v-btn>
        <v-btn color="#5ceabb" class="w-auto ma-2 black--text" @click="goRegister">
            <div class="d-none d-lg-block">
                Registro
            </div>
            <v-icon right dark>
                mdi-account-plus-outline
            </v-icon>
        </v-btn>
    </div>
</template>

<script>

export default {
    name: 'NavloginComponent',
    mounted() { },
    created() { },
    computed: { },
    methods: {
        goLogin() {
            this.$router.push("/login").catch(err => { err });
        },
        // goRegister() {
        //   this.$router.push("/register").catch(err => {err});
        // },
        goRegister() {
            this.$router.push("/form_new_user").catch(err => { err });
        },
    },
    beforeDestroy() {
        // EventBus.remove("logout");
    },
    data() {
        return {
            
        };
    }

}
</script>