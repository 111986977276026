<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Elige metodo de pago." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                         <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">ELIGE METODO DE PAGO</strong>
                                        </p>
                                        <br>
                                        <v-card title="CRIPTOMONEDAS." class="mx-auto">
                                            <v-card-text class="text-center">
                                                CRIPTOMONEDAS
                                                <v-btn class="ma-2" text icon @click="openCripto">
                                                    <v-icon>mdi-play</v-icon>
                                                </v-btn>
                                            </v-card-text>
                                            
                                        </v-card>
                                        <!-- <v-card title="AMAZON PAY." class="mx-auto">
                                            <v-card-text class="text-center">
                                                AMAZON PAY
                                                <v-btn class="ma-2" text icon @click="openAmazon">
                                                    <v-icon>mdi-play</v-icon>
                                                </v-btn>
                                            </v-card-text>
                                        </v-card> -->
                                    </v-card-text>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>


export default {
    name: 'SelectModalidadComponent',
    data: function () {
        return {
            showModal: true,
            item: '',
            idSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',

            dialog: false,
            advertising: true,
            performance: true,
            cookieDuration: 30,
            cookieValue: 'accepted',
            cookieConsent: false
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },
        closeModal() {
            this.dialog = false
        },
        openCripto() {
            this.$emit('selection', 'cripto');
        },
        openAmazon() {
            this.$emit('selection', 'amazon');
        },
    },
    mounted() {

    }
}
</script>