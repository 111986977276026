<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                            <!-- <v-divider class="mx-4"></v-divider> -->
                        </v-row>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <!-- <v-card-title class="text-h5">{{ state }}</v-card-title> -->
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                            <p class="subheading font-weight-regular">
                                                <br>La operación se ha completado.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                            <v-data-table :headers="headers" :items="items" class="elevation-1"
                                                hide-default-footer></v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>

                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <br>
                                    <br>Total: {{ total }} $
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <!-- <v-btn color="success" @click="postConfimar">
                                        CONFIRMAR
                                    </v-btn> -->
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto">
            <SuccessModal ref="modalSuccess" />
        </div>
        <div class="w-auto">
            <CancelModal ref="modalCancel" />
        </div>
    </v-container>
</template>

<script>
// import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import SuccessModal from '@/components/amazon/AmazonSuccessComponent.vue'
import CancelModal from '@/components/amazon/AmazonCancelComponent.vue'
// import User from '../models/user';

export default {
    name: 'CriptoResultComponent',
    components: {
        SuccessModal,
        CancelModal
    },
    data() {
        return {
            title: 'RESULT',
            idSuscripcion: '',
            orderId: '',
            session: '',
            calle: '',
            ciudad: '',
            pais: '',
            tarjeta: '',
            cantidad: '',
            // Table
            headers: [
                { text: "Nombre", value: "nombre", align: "center" },
                { text: "Descripcion", value: "descripcion", align: "center" },
                { text: "Limite Bot", value: "limiteInversionPorBot", align: "center" },
                { text: "Dias", value: "dias", align: "center" },
                { text: "Precio", value: "precio", align: "center" }
            ],
            items: [],
            total: '',
            status: '',
            statusAPI: '',
            content: ''
        };
    },
    created() {
        // console.log(this.$route.query) // outputs 'yay'
        // let keys = Object.keys(this.$route.query)
        let values = Object.values(this.$route.query)
        // console.log(values.length)
        // console.log(keys)



        if (values.length === 1) {
            console.log('COIN PAYMENTS')
        } else {
            const paramArr = values[0].split('?');
            console.log(paramArr)
            this.idSuscripcion = paramArr[0]
            const orderArr = paramArr[1].split('=')
            this.orderId = orderArr[1]

            console.log(this.idSuscripcion)
            console.log(this.orderId)
            UserService.finalizeOrder(this.idSuscripcion, this.orderId).then(
                response => {
                    // console.log(response.data)
                    this.items.push(response.data);
                    // this.total = this.items[0].precio
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // EventBus.dispatch("logout");
                }
            );
        }


    },
    computed: {},
    mounted() {

    },
    methods: {
        postComplete() {
            UserService.postCompleteCheckoutSession(this.session, this.idSuscripcion, this.cantidad).then(
                response => {
                    // console.log('Get CheckOut Success')
                    // console.log(response)
                    this.content = response
                    console.log(this.cantidad)
                    UserService.postFinalizeCheckoutSession(this.session, this.idSuscripcion, this.cantidad).then(
                        response => {
                            // console.log('Finalize CheckOut Success')
                            // console.log(response.statusDetails.state)
                            if (response.statusDetails.state === 'Completed') {
                                this.$refs.modalSuccess.openModal()
                            } else {
                                this.$refs.modalCancel.message = response.statusDetails.reasonDescription
                                this.$refs.modalCancel.openModal()
                            }
                        },
                        error => {
                            this.content =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log(error)
                            this.messages.value.push(error);
                        }
                    );
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(error)
                    this.messages.value.push(error);
                }
            );
        }
    }
}
</script>