<template>
    <AdminPagosSaveComponent />
</template>

<script>
import AdminPagosSaveComponent from '../../components/admin/AdminPagosSaveComponent.vue'

export default {
    name: 'AdminPagosSaveView',

    components: {
        AdminPagosSaveComponent,
    },

}
</script>