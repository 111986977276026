<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="5" xs="0" sm="0" md="5">
                            </v-col>
                            <v-col cols="2" xs="2" sm="2" md="2">
                                <!-- <v-row no-gutters justify="center">
                                    <v-btn color="success" @click="menuActionClick('openDialogBot')">
                                        Nuevo Bot
                                    </v-btn>
                                </v-row> -->
                            </v-col>
                        </v-row>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-data-table :headers="headers" :items="desserts" :search="search">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'orders')">mdi-play</v-icon>
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'update')">mdi-content-save-edit-outline</v-icon>
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'delete')">mdi-trash-can-outline</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent :type="types">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Eliminar Bot
                </v-card-title>

                <v-card-text>
                    ¿ Desea realmente eliminar el bot ?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="dialog = false">
                        Cancelar
                    </v-btn>

                    <v-btn color="green darken-1" text @click="eliminarBot">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Bot from "../../models/bot";

export default {
    name: 'BotComponent',
    components: {
    },
    data() {
        return {
            title: 'BOTS',
            // Variables
            bot: new Bot("", "", "", ""),
            content: "",
            right: null,
            loading: false,
            message: "",
            valid: '',
            // Table
            headers: [
                { text: "Par", value: "pair", align: "center" },
                { text: "Cantidad por Trade", value: "quantity", align: "center" },
                { text: "Cantidad Bot", value: "amount", align: "center" },
                { text: "En marcha", value: "started", align: "center" },
                { text: "Porcentaje", value: "percent", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            desserts: [],
            items: [
                {
                    title: "Crear Bot",
                    icon: "mdi-view-dashboard",
                    action: "openDialogBot"
                }
            ],
            search: '',
            isShow: false,
            msgError: '',
            apiError: false,
            types: "success",
            dialog: false
        };
    },
    computed: { },
    created() { },
    mounted() {
        UserService.getListBots(this.$store.state.auth.user.email).then(
            response => {
                // console.log(response.data)
                if (response.data !== '') {
                    this.desserts = response.data;
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
        // UserService.getListSymbols().then(
        //     response => {
        //         this.itemsSymbols = response.data;
        //     },
        //     error => {
        //         this.content =
        //             (error.response &&
        //                 error.response.data &&
        //                 error.response.data.message) ||
        //             error.message ||
        //             error.toString();
        //     }
        // );
    },
    methods: {
        // menuActionClick(action) {
        //     if (action === "openDialogBot") {
        //         this.$router.push('/bot_save');
        //     }
        // },
        handleClick(value, action) {
            if (this.apiError === false) {
                switch (action) {
                    case 'orders':
                        this.$router.push('/orders/' + value.id);
                        break;
                    case 'update':
                        this.$router.push('/bot_save/?idBot=' + value.id);
                        break;
                    case 'delete':
                        this.bot = value
                        this.dialog = true
                        break;
                }
            } else {
                this.msgError = 'Verifique su API.'
                this.isShow = true
            }
        },
        eliminarBot() {
            this.dialog = false
            UserService.deleteBot(this.bot).then(
                response => {
                    this.content = response.data
                    this.$router.go()
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>