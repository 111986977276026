export default class Suscripcion {
    constructor(id, user, tipoSuscripcion, bot, fechaInicio, fechaFin, activa) {

        this.id = id
        this.user = user
        this.tipoSuscripcion = tipoSuscripcion
        this.bot = bot
        this.fechaInicio = fechaInicio
        this.fechaFin = fechaFin
        this.activa = activa
    }
}
