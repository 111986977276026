<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <!-- <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="text-none" color="blue-darken-4" variant="outlined" v-bind="attrs"
                                        v-on="on">
                                        Manage Cookies
                                    </v-btn>
                                </template> -->
                                <v-card title="Terminos y Condiciones" class="mx-auto">
                                    <v-card-text>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">T&Eacute;RMINOS Y CONDICIONES DE USO DE
                                                TRADEMASTERBOTS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            &Uacute;ltima actualizaci&oacute;n:
                                            01 de Septiembre de 2024</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">1. ACEPTACI&Oacute;N DE LOS
                                                T&Eacute;RMINOS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">Al
                                            registrarse o utilizar
                                            TRADEMASTERBOTS app de bots de criptomonedas (&quot;TRADEMASTERBOTS&quot;),
                                            usted acepta estar legalmente
                                            vinculado por los presentes T&eacute;rminos y Condiciones. Si no est&aacute;
                                            de acuerdo con estos
                                            T&eacute;rminos, no utilice TRADEMASTERBOTS.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">2. DEFINICIONES</strong></p>
                                        <ul>
                                            <li>
                                                <p
                                                    style="line-height: 115%;margin-bottom: 0cm;background: transparent;">
                                                    <strong style="font-weight: bold;">Plataforma</strong>: El software
                                                    y los servicios ofrecidos por
                                                    INNOVATIONS SOUTIONS AT LLC.</p>
                                            </li>
                                            <li>
                                                <p
                                                    style="line-height: 115%;margin-bottom: 0cm;background: transparent;">
                                                    <strong style="font-weight: bold;">Usuario</strong>: Cualquier
                                                    persona que utilice TRADEMASTERBOTS.</p>
                                            </li>
                                            <li>
                                                <p
                                                    style="line-height: 115%;margin-bottom: 0cm;background: transparent;">
                                                    <strong style="font-weight: bold;">TRADEMASTERBOTS</strong>:
                                                    Software automatizado utilizado para
                                                    ejecutar operaciones en mercados de criptomonedas.</p>
                                            </li>
                                            <li>
                                                <p
                                                    style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                    <strong style="font-weight: bold;">Criptomonedas</strong>: Monedas
                                                    digitales descentralizadas, como
                                                    Bitcoin, Ethereum, entre otras.</p>
                                            </li>
                                        </ul>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">3. SERVICIOS OFRECIDOS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            TRADEMASTERBOTS permite a los
                                            Usuarios acceder a bots de criptomonedas automatizados para realizar
                                            transacciones en mercados de
                                            criptomonedas basados en estrategias predefinidas. Estos servicios incluyen,
                                            entre otros, la
                                            configuraci&oacute;n de estrategias de trading, la integraci&oacute;n con
                                            exchanges, y el seguimiento de
                                            resultados.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">4. REQUISITOS DE ELEGIBILIDAD</strong>
                                        </p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">Para
                                            usar TRADEMASTERBOTS, usted
                                            debe tener al menos 18 a&ntilde;os de edad y cumplir con las leyes
                                            aplicables en su jurisdicci&oacute;n. El
                                            Usuario es responsable de garantizar que el uso de TRADEMASTERBOTS sea legal
                                            en su pa&iacute;s o
                                            regi&oacute;n.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">5. DESCARGO DE RESPONSABILIDAD</strong>
                                        </p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">5.1
                                            <strong style="font-weight: bold;">Riesgo de Inversi&oacute;n</strong>: El
                                            comercio de criptomonedas implica
                                            riesgos significativos, incluyendo la posibilidad de perder la totalidad de
                                            su inversi&oacute;n.
                                            TRADEMASTERBOTS no garantiza ning&uacute;n resultado financiero y no se hace
                                            responsable por p&eacute;rdidas
                                            resultantes del uso de los bots.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">5.2
                                            <strong style="font-weight: bold;">Funcionamiento de los Bots</strong>:
                                            Aunque los bots est&aacute;n
                                            dise&ntilde;ados para operar de manera eficiente, pueden ocurrir fallos
                                            t&eacute;cnicos, errores de
                                            programaci&oacute;n, o interrupciones en los servicios que puedan afectar el
                                            rendimiento de las
                                            transacciones.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">5.3
                                            <strong style="font-weight: bold;">Exenci&oacute;n de
                                                Responsabilidad</strong>: TRADEMASTERBOTS no es
                                            responsable de ninguna p&eacute;rdida, da&ntilde;o o perjuicio derivado de
                                            la operaci&oacute;n de los bots o
                                            del uso de criptomonedas en general.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">6. CONDUCTA DEL USUARIO</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">El
                                            Usuario se compromete a utilizar
                                            TRADEMASTERBOTS &uacute;nicamente para los fines permitidos por estos
                                            T&eacute;rminos y cualquier ley
                                            aplicable. Est&aacute; prohibido el uso de TRADEMASTERBOTS para actividades
                                            ilegales, incluyendo pero no
                                            limitado a, fraude, lavado de dinero, y financiaci&oacute;n del terrorismo.
                                        </p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">7. PROPIEDAD INTELECTUAL</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            Todos los derechos de propiedad
                                            intelectual relacionados con TRADEMASTERBOTS, incluidos pero no limitados a
                                            software, gr&aacute;ficos, y
                                            contenido, pertenecen a INNOVATIONS SOUTIONS AT LLC o sus licenciantes. Los
                                            Usuarios no tienen derechos de
                                            propiedad sobre ning&uacute;n aspecto de TRADEMASTERBOTS.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">8. CUENTAS Y SEGURIDAD</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">El
                                            Usuario es responsable de
                                            mantener la confidencialidad de su cuenta, contrase&ntilde;as, y cualquier
                                            informaci&oacute;n de acceso a
                                            TRADEMASTERBOTS. TRADEMASTERBOTS no ser&aacute; responsable de ninguna
                                            p&eacute;rdida o da&ntilde;o
                                            resultante del acceso no autorizado a su cuenta.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">9. TARIFAS Y PAGOS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">El
                                            uso de TRADEMASTERBOTS puede
                                            estar sujeto a tarifas, comisiones, o suscripciones seg&uacute;n lo
                                            determinado por INNOVATIONS SOUTIONS AT
                                            LLC. Todas las tarifas aplicables se detallar&aacute;n en TRADEMASTERBOTS.
                                            La falta de pago de cualquier
                                            tarifa puede dar lugar a la suspensi&oacute;n o cancelaci&oacute;n de su
                                            cuenta.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">10. MODIFICACIONES A LOS
                                                T&Eacute;RMINOS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            INNOVATIONS SOUTIONS AT LLC se
                                            reserva el derecho de modificar estos T&eacute;rminos en cualquier momento.
                                            Cualquier modificaci&oacute;n
                                            ser&aacute; efectiva cuando se publique en TRADEMASTERBOTS. El uso
                                            continuado de TRADEMASTERBOTS tras la
                                            publicaci&oacute;n de las modificaciones constituir&aacute; su
                                            aceptaci&oacute;n de dichos cambios.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">11. TERMINACI&Oacute;N</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            TRADEMASTERBOTS puede suspender o
                                            terminar su acceso a los servicios en cualquier momento sin previo aviso si
                                            considera que el Usuario ha
                                            violado estos T&eacute;rminos o cualquier ley aplicable.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">12. LIMITACI&Oacute;N DE
                                                RESPONSABILIDAD</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">En
                                            ning&uacute;n caso INNOVATIONS
                                            SOUTIONS AT LLC ser&aacute; responsable por da&ntilde;os indirectos,
                                            incidentales, punitivos o consecuentes
                                            derivados del uso de TRADEMASTERBOTS, incluso si se ha informado sobre la
                                            posibilidad de tales da&ntilde;os.
                                        </p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">13. LEY APLICABLE Y RESOLUCI&Oacute;N DE
                                                CONFLICTOS</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            Estos T&eacute;rminos se
                                            regir&aacute;n por las leyes de Estados Unidos de América. Cualquier disputa
                                            relacionada con estos
                                            T&eacute;rminos ser&aacute; resuelta exclusivamente por los tribunales de
                                            Estados Unidos de América.</p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">14. CONTACTO</strong></p>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">Si
                                            tiene preguntas sobre estos
                                            T&eacute;rminos, puede contactarnos en:<br><a
                                                href="mailto:atcliente@trademasterbots.com">atcliente@trademasterbots.com</a><br>&nbsp;Albuquerque, Nuevo México. USA</p>
                                        <p style="line-height: 100%;margin-bottom: 0cm;background: transparent;"><br>
                                        </p>


                                    </v-card-text>

                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>
export default {
    name: 'TerminosCondicionesComponent',
    data: function () {
        return {
            showModal: true,
            dialog: false,
            advertising: true,
            performance: true,
            cookieDuration: 30,
            cookieValue: 'accepted',
            cookieConsent: false
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },
        decline() {
            // this.toggleScroll(true)
            // // this.emit("toggleScroll", true);
            // // this.emit("close");
        },
    },
    mounted() {

    }
}
</script>