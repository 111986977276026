<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                            <!-- <v-divider class="mx-4"></v-divider> -->
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                                        <v-card elevation="2" outlined shaped>
                                            <v-row no-gutters justify="center">
                                                <v-card-title class="text-center">Suscripción {{ this.estado
                                                    }}</v-card-title>
                                                <br>
                                            </v-row>
                                            <br>
                                        </v-card>
                                    </v-row>
                                </v-card>
                                <v-card>
                                    <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                                        <!-- <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                                        </v-col> -->
                                        <v-col cols="12" xs="12" sm="12" md="12">
                                            <v-card elevation="2" outlined shaped>
                                                <v-row no-gutters>
                                                    <v-col cols="1" xs="0" sm="0" md="1"
                                                        class="flex-grow-0 flex-shrink-0"></v-col>
                                                    <v-col cols="10" xs="12" sm="12" md="10"
                                                        class="flex-grow-0 flex-shrink-0">
                                                        <v-row no-gutters justify="center">
                                                            <v-card-title
                                                                class="text-h5 text-center">BOTS</v-card-title>
                                                        </v-row>
                                                    </v-col>
                                                    <br>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="1" xs="0" sm="0" md="1"
                                                        class="flex-grow-0 flex-shrink-0"></v-col>
                                                    <v-col cols="10" xs="12" sm="12" md="10"
                                                        class="flex-grow-0 flex-shrink-0">
                                                        <v-row no-gutters justify="center">
                                                            <v-data-table :headers="headersItems" :items="itemsCart"
                                                                :search="search">
                                                                <template v-slot:[`item.actions`]="{ item }">
                                                                    <div v-if="!item.bot">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-icon small class="mr-2" dark
                                                                                    v-on="on"
                                                                                    @click="handleClick(item, 'addBot')">mdi-robot</v-icon>
                                                                            </template>
                                                                            <span>Agregar Bot</span>
                                                                        </v-tooltip>
                                                                    </div>

                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" dark v-on="on"
                                                                                @click="handleClick(item, 'pay')">mdi-wallet</v-icon>
                                                                        </template>
                                                                        <span>Pagar</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" dark v-on="on"
                                                                                @click="handleClick(item, 'cancel')">mdi-delete</v-icon>
                                                                        </template>
                                                                        <span>Cancelar</span>
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-data-table>
                                                            <!-- <v-data-table :headers="headersBots" :items="itemsBots"
                                                                :search="search"></v-data-table> -->
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters justify="center">
                                                    <!-- <v-btn :disabled="invalid" color="success"
                                                        @click="menuActionClick('newBot')">
                                                        Agregar Bot
                                                    </v-btn> -->

                                                    <div v-if="masSuscripcion">
                                                        <v-btn color="success" @click="menuActionClick('renovarTodo')">
                                                            Pagar Todo
                                                        </v-btn>
                                                    </div>

                                                </v-row>
                                                <br>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                            <v-data-table :headers="headersPays" :items="itemsPays"
                                                :search="search"></v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <div v-if="!noMasBots">
                            <v-row no-gutters>
                                <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                                <v-col cols="6" xs="2" sm="2" md="2">
                                    <v-row no-gutters justify="center">
                                        <v-btn color="blue-grey" @click="comprarBot" :disabled="isFree">
                                            COMPRAR BOT
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <br>
                        </div>

                    </v-card>
                </v-col>
            </v-row>
            <div class="w-auto" v-show="isOpenDialog">
                <DialogMessages ref="dialog" />
            </div>
            <!-- <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                <v-col cols="10" xs="8" sm="8" md="4">
                    <v-alert prominent :type="types">
                        <v-row>
                            <v-col class="grow">
                                {{ msgError }}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn @click="closeAlert">OK</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row> -->
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Type from '../../models/type'

import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'

export default {
    name: 'CartComponent',
    components: {
        DialogMessages
    },
    data() {
        return {
            idBot: '',
            title: "MI CUENTA",
            typeDetail: '',
            estado: '',
            type: new Type('', ''),
            fechaInicio: '',
            fechaFin: '',
            // Table Items
            headersItems: [
                { text: "Nombre", value: "tipoSuscripcion.nombre", align: "center" },
                { text: "Descripcion", value: "tipoSuscripcion.descripcion", align: "center" },
                { text: "Limite Bot", value: "tipoSuscripcion.limiteInversionPorBot", align: "center" },
                { text: "Par", value: "bot.pair", align: "center" },
                { text: "Iniciado", value: "bot.started", align: "center" },
                { text: "Fecha Fin", value: "fechaFin", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            itemsCart: [],
            bots: [],
            // Table bots
            // Table
            headersBots: [
                { text: "Par", value: "pair", align: "center" },
                { text: "Cantidad por Trade", value: "quantity", align: "center" },
                { text: "Cantidad Bot", value: "amount", align: "center" },
                { text: "En marcha", value: "started", align: "center" },
                { text: "Porcentaje", value: "percent", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            itemsBots: [],
            // Table
            headersPays: [
                { text: "Fecha Pago", value: "fechaPago", align: "center" },
                { text: "Metodo Pago", value: "metodoPago", align: "center" },
                { text: "Precio", value: "monto", align: "center" },
                { text: "Estado Pago", value: "estadoPago", align: "center" },
            ],
            itemsPays: [],
            search: '',
            start: false,
            isShow: false,
            msgError: '',
            types: "success",
            invalid: true,
            isFree: true,
            isOpenDialog: false,
            masSuscripcion: false,
            diasRestantes: '',
            noMasBots: false
        };
    },
    computed: {},
    mounted() {
        UserService.getCart().then(
            response => {
                // this.itemsCart = response;
                // console.log(response.data)
                this.diasRestantes = response.data.diasRestantes
                if (response.data.listSuscripcion[0].tipoSuscripcion.id === 1) {
                    this.isFree = true
                } else {
                    this.isFree = false
                }
                if (response.data.estado !== 'false') {
                    this.estado = 'ACTIVADA'
                } else {
                    this.estado = 'FINALIZADA'
                }
                if (response.data.botsDisponibles) {
                    this.invalid = false
                } else {
                    this.invalid = true
                }
                if (response.data.listSuscripcion.length > 1 && this.diasRestantes < 15) {
                    // console.log('Mas de una suscripcion')
                    this.masSuscripcion = true
                }
                if (response.data.listSuscripcion.length === 5) {
                    // console.log('Mas de una suscripcion')
                    this.noMasBots = true
                }
                this.itemsCart = response.data.listSuscripcion
                this.itemsPays = response.data.listPago

            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        menuActionClick(action) {
            // if (action === "newBot") {
            //     this.$router.push('/bot_save');
            // }
            if (action === "renovarTodo") {

                this.$router.push("/menu_suscripcion/?accion=update&idSuscripcion=all");
            }
        },
        handleClick(value, action) {
            switch (action) {
                case 'addBot':

                    if (value.bot === null) {
                        // console.log(value.bot)
                        this.$router.push('/bot_save/?idSuscripcion=' + value.id);
                    }

                    break;
                case 'pay':
                    this.checkPeriodBot(value)
                    break;
                case 'cancel':
                    this.cancelSuscripcion(value)
                    break;
            }
        },
        checkPeriodBot(value) {
            UserService.chekPeriodBot(value.id).then(
                response => {
                    if (response) {
                        if (response.data > 15) {
                            this.msgError = 'Todavia le faltan ' + response.data + ' días para que finalice la suscripcón. '
                                + 'Si quiere renovar la suscripción, abra un ticket'
                            this.types = 'success'
                            this.$refs.dialog.openModal()
                            this.$refs.dialog.tituloDialog = 'ACTUALIZACIÓN DE SUSCRIPCIÓN'
                            this.$refs.dialog.msgError = this.msgError
                            this.$refs.dialog.types = this.types
                            this.$refs.dialog.isShow = true
                            // this.isShow = true
                        } else {
                            // localStorage.setItem('suscripcion', value.id)
                            this.$router.push("/menu_suscripcion/?accion=update&idSuscripcion=" + value.id);
                            // ?suscripcion=" + this.idSuscripcion + '&tipo=' + this.selected.id
                        }
                    }
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        cancelSuscripcion(value) {
            UserService.cancelarSuscripcion(value.id).then(
                response => {
                    if (response) {
                        console.log(response)
                    }
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        comprarBot() {
            this.$router.push("/menu_suscripcion/?accion=new");
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>