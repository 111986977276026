<template>
    <FormNewUserComponent />
</template>

<script>
import FormNewUserComponent from '../../components/public/FormNewUserComponent.vue'

export default {
    name: 'FormNewUserView',
    components: {
        FormNewUserComponent,
    },
};
</script>