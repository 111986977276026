<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Resultado del pago." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">EL PAGO SE HA CANCELADO</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">{{ message }}</strong>
                                        </p>
                                        <br>
                                        <v-row no-gutters>
                                            <v-col cols="2" xs="1" sm="1" md="2"> </v-col>
                                            <v-col cols="3" xs="4" sm="4" md="3">
                                                <v-row no-gutters justify="center">
                                                    <v-btn color="success" @click="signOut">
                                                        SINGOUT (AMAZON)
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="2" xs="2" sm="2" md="2"> </v-col>
                                            <v-col cols="3" xs="4" sm="4" md="3">
                                                <v-row no-gutters justify="center">
                                                    <v-btn color="success" @click="goTo">
                                                        ACEPTAR
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-btn class="ma-2" color="error" text icon @click="goHome">
                                            ACEPTAR
                                        </v-btn> -->
                                    </v-card-text>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>

export default {
    name: 'AmazonCancelComponent',
    data() {
        return {
            showModal: true,
            dialog: false,
            message: ''
        };
    },
    mounted() {
    },
    computed: {
    },
    created() {
    },
    methods: {
        openModal() {
            this.dialog = true
        },
        goTo() {
            this.$router.push('/suscripcion');
        },
        signOut() {
            window.amazon.Pay.signout();
        }
    }
}
</script>