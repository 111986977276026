<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="5" xs="0" sm="0" md="5">
                            </v-col>
                            <v-col cols="2" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <v-btn color="success" @click="menuActionClick('openDialogBot')">
                                        Nueva API
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <!-- <v-row justify="center">
                                    <v-col cols="6" xs="0" sm="0" md="6" class="flex-grow-0 flex-shrink-0">
                                        <v-card-title>
                                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"
                                                single-line hide-details></v-text-field>
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                        <v-list dense nav>
                                            <v-list-item v-for="item in items" :key="item.title"
                                                @click="menuActionClick(item.action)">
                                                <v-list-item-icon>
                                                    <v-icon>{{ item.icon }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row> -->
                                <v-data-table :headers="headers" :items="desserts" :search="search">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <!-- <v-icon small class="mr-2" @click="handleClick(item, 'orders')">mdi-play</v-icon> -->
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'update')">mdi-content-save-edit-outline</v-icon>
                                        <v-icon small class="mr-2"
                                            @click="handleClick(item, 'delete')">mdi-trash-can-outline</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-card>

                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';

export default {
    name: 'ClientComponent',
    components: {
    },
    data() {
        return {
            title: 'APIS',
            // Variables
            content: "",
            right: null,
            loading: false,
            message: "",
            valid: '',
            // Table
            headers: [
                { text: "Exchange", value: "exchange", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            desserts: [],
            items: [
                {
                    title: "Nuevo Cliente",
                    icon: "mdi-view-add",
                    action: "openDialogBot"
                }
            ],
            search: '',
            isShow: false,
            msgError: '',
            apiError: false,
            types: "success",
        };
    },
    computed: {},
    created() {
        if (this.$store.state.user === "") {
            EventBus.dispatch("logout");
            this.$store.dispatch("auth/logout");

            this.$router.push("/");
        }
    },
    mounted() {
        UserService.getClients(this.$store.state.auth.user.email).then(
            response => {
                if (response.data !== '') {
                    for (let i = 0; i < response.data.length; i++) {
                        let aux = { 'id': response.data[i]['id'], 'exchange': response.data[i]['exchange']['exchange'] }
                        this.desserts.push(aux);
                    }
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        menuActionClick(action) {
            if (action === "openDialogBot") {
                this.$router.push('/client_save');
            }
        },
        handleClick(value, action) {
            if (this.apiError === false) {
                switch (action) {
                    case 'orders':
                        localStorage.bot = JSON.stringify(value.id);
                        this.$router.push('/orders');
                        break;
                    case 'update':
                        this.$router.push('/client_save/' + value.id);
                        break;
                    case 'delete':
                        console.log('delete client')
                        break;
                }
            } else {
                this.msgError = 'Verifique su API.'
                this.isShow = true
            }
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>