<template>
    <TicketComponent />
  </template>
    
  <script>
  import TicketComponent from '../../components/user/TicketComponent.vue'
  
  export default {
    name: "TicketView",
    components: {
        TicketComponent,
    },
  
  };
  </script>
    
  <style lang="scss" scoped></style>