<template>
    <ClientSaveComponent/>
  </template>
  
  <script>
  import ClientSaveComponent from '../../components/user/ClientSaveComponent.vue'
  
  export default {
    name: 'ClientSaveView',
    components: {
        ClientSaveComponent,
    },
    
  };
  </script>