<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <validation-observer ref="observer" v-slot="{ invalid }" name="observer">
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-row no-gutters style="flex-wrap: nowrap;">
                                            <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                                <div v-if="isBot">
                                                    <validation-provider v-slot="{ errors }" name="Exchange"
                                                        rules="required">
                                                        <v-text-field v-model="exchange.exchange"
                                                            :error-messages="errors" label="Exchange" readonly
                                                            disabled></v-text-field>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Riesgo"
                                                        rules="required">
                                                        <v-text-field v-model="riesgo.riesgo" :error-messages="errors"
                                                            label="Riesgo" readonly disabled></v-text-field>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Par"
                                                        rules="required">
                                                        <v-text-field v-model="pair" :error-messages="errors"
                                                            label="Par" readonly disabled></v-text-field>
                                                    </validation-provider>
                                                </div>
                                                <div v-if="!isBot">
                                                    <validation-provider v-slot="{ errors }" name="Exchange"
                                                        rules="required">
                                                        <v-select v-model="exchange" :items="itemsExchange"
                                                            item-text="exchange" item-value="id"
                                                            label="Selecciona el Exchange" persistent-hint return-object
                                                            single-line></v-select>
                                                        <div class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Riesgo"
                                                        rules="required">
                                                        <v-select v-model="riesgo" :items="itemsRiesgo"
                                                            item-text="riesgo" item-value="id"
                                                            label="Selecciona el tipo de Riesgo"
                                                            @change="onChange($event)" persistent-hint return-object
                                                            single-line></v-select>
                                                        <div class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Par"
                                                        rules="required">
                                                        <v-select return-object v-model="pair" :items="itemsSymbols"
                                                            label="Pares"></v-select>
                                                        <div name="pares" class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                </div>
                                                <validation-provider v-slot="{ errors }" name="Total Bot"
                                                    rules="required">
                                                    <v-text-field v-model="amount" :error-messages="errors"
                                                        label="Total Bot" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Cantidad"
                                                    rules="required">
                                                    <v-text-field v-model="quantity" :error-messages="errors"
                                                        label="Cantidad por orden" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Porcentaje"
                                                    rules="required">
                                                    <v-text-field v-model="percent" :error-messages="errors"
                                                        label="Porcentaje" required></v-text-field>
                                                </validation-provider>
                                                <!-- <validation-provider v-slot="{ errors }" name="Gestión Riesgo"
                                                    rules="required">
                                                    <v-text-field v-model="gest_riesgo" :error-messages="errors"
                                                        label="Gestión Riesgo" required></v-text-field>
                                                </validation-provider> -->
                                                <v-row no-gutters style="flex-wrap: nowrap;">
                                                    <v-subheader>Gestión Riesgo (Opcional)</v-subheader>
                                                    <v-btn class="ma-2" text icon @click="opcionalGestionRiesgo">
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-row v-show="optionalShow" no-gutters style="flex-wrap: nowrap;">
                                                    <v-text-field v-model="gest_riesgo" :error-messages="errors"
                                                        hint="Gestión Riesgo" required></v-text-field>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters>
                                                    <v-col cols="4" xs="0" sm="0" md="5">
                                                    </v-col>
                                                    <v-col cols="4" xs="2" sm="2" md="2">
                                                        <v-row no-gutters justify="center">
                                                            <v-btn :disabled="invalid" color="success"
                                                                @click="prepareData">Guardar</v-btn>
                                                        </v-row>

                                                    </v-col>
                                                </v-row>
                                                <br>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </validation-observer>
                            </v-col>
                        </v-row>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent :type="types">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus"
import UserService from '../../services/user.service'
import User from '../../models/user'
import Bot from "../../models/bot"
import Suscripcion from "@/models/suscripcion"
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import Client from "@/models/client";

setInteractionMode('eager')

extend('required', {
    ...required,
    message: 'El/La {_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: 'El/La {_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

export default {
    name: 'NewBotComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            accion: '',
            name: 'observer',
            title: 'NUEVO BOT',
            content: '',
            // Variables
            user: new User(),
            client: new Client(),
            bot: new Bot("", "", "", ""),
            suscripcion: new Suscripcion(''),
            idSuscripcion: '',
            idBot: '',
            right: null,
            loading: false,
            message: '',
            // Dialog Bot
            exchange: '',
            itemsExchange: [],
            riesgo: '',
            itemsRiesgo: [],
            itemsSymbols: [],
            pair: '',
            quantity: '',
            amount: '',
            percent: '',
            gest_riesgo: '',
            search: '',
            valid: '',
            isShow: false,
            msgError: '',
            types: "success",
            optionalShow: false,
            errors: '',
        };
    },
    computed: {
        computedObserver() {
            return this.$refs.observer;
        },
        isBot() {
            let keys = Object.keys(this.$route.query)
            if (keys[0] === 'idBot') {
                // console.log('Save Bot')
                return true
            } else {
                return false
            }

            // if (this.$route.params.idBot !== '' && this.$route.params.idBot !== undefined) {
            //     return true
            // } else {
            //     return false
            // }
        }
    },
    created() {
        if (this.$store.state.user === "") {
            EventBus.dispatch("logout");
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
        } else {
            UserService.getClients(this.$store.state.auth.user.email).then(
                response => {
                    if (response.data !== '') {
                        for (let i = 0; i < response.data.length; i++) {
                            let aux = { 'id': response.data[i]['id'], 'exchange': response.data[i]['exchange']['exchange'] }
                            this.itemsExchange.push(aux);
                        }
                    }
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    },
    mounted() {
        let keys = Object.keys(this.$route.query)
        let values = Object.values(this.$route.query)
        // console.log(keys)
        if (keys[0] === 'idBot') {
            this.accion = 'save'
            // console.log('Save Bot')
            this.idBot = values[0]
            this.title = 'ACTUALIZAR BOT'
            this.chargeBot()
        } else {
            // console.log('Add Bot')
            this.accion = 'new'
            this.idSuscripcion = values[0]
            this.title = 'CREAR BOT'
            this.chargeSuscripcion()
        }
        // if (this.$route.params.idBot !== null && this.$route.params.idBot !== undefined) {
        //     this.idBot = this.$route.params.idBot
        // }

        UserService.getRiesgos().then(
            response => {
                for (let i = 0; i < response.data.length; i++) {
                    let aux = { 'id': response.data[i]['id'], 'riesgo': response.data[i]['riesgo'] }
                    this.itemsRiesgo.push(aux);
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );

        // if (this.idBot !== '' && this.idBot !== undefined) {
        //     UserService.getBot(this.idBot).then(
        //         response => {
        //             this.content = response.data
        //             this.client.id = this.content.client.id
        //             this.exchange = this.content.client.exchange
        //             UserService.getListByRiesgo(this.content.riesgo.riesgo).then(
        //                 response => {
        //                     this.itemsSymbols = response.data;
        //                 },
        //                 error => {
        //                     this.content =
        //                         (error.response &&
        //                             error.response.data &&
        //                             error.response.data.message) ||
        //                         error.message ||
        //                         error.toString();
        //                     this.msgError = error.response.data
        //                     this.types = 'error'
        //                     this.isShow = true
        //                 }
        //             );
        //             this.riesgo = this.content.riesgo
        //             this.pair = this.content.pair
        //             this.amount = this.content.amount
        //             this.quantity = this.content.quantity
        //             this.percent = this.content.percent
        //             this.gest_riesgo = this.content.gest_riesgo

        //         },
        //         error => {
        //             this.content =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();

        //             if (error.response && error.response.status === 403) {
        //                 EventBus.dispatch("logout");
        //             }
        //         }
        //     );
        // } else {
        //     UserService.getSuscripcion(this.idSuscripcion).then(
        //         response => {
        //             // console.log(response.data)
        //             this.suscripcion = response.data
        //         },
        //         error => {
        //             this.content =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();

        //             if (error.response && error.response.status === 403) {
        //                 EventBus.dispatch("logout");
        //             }
        //         }
        //     );
        // }
    },
    methods: {
        chargeBot() {
            UserService.getBot(this.idBot).then(
                response => {
                    this.content = response.data
                    this.client.id = this.content.client.id
                    this.exchange = this.content.client.exchange
                    UserService.getListByRiesgo(this.content.riesgo.riesgo).then(
                        response => {
                            this.itemsSymbols = response.data;
                        },
                        error => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.msgError = error.response.data
                            this.types = 'error'
                            this.isShow = true
                        }
                    );
                    this.riesgo = this.content.riesgo
                    this.pair = this.content.pair
                    this.amount = this.content.amount
                    this.quantity = this.content.quantity
                    this.percent = this.content.percent
                    this.gest_riesgo = this.content.gest_riesgo

                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        chargeSuscripcion() {
            UserService.getSuscripcion(this.idSuscripcion).then(
                response => {
                    // console.log(response.data)
                    this.suscripcion = response.data
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        onChange(value) {
            UserService.getListByRiesgo(this.exchange.exchange, value.riesgo).then(
                response => {
                    this.itemsSymbols = response.data;
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    this.types = 'error'
                    this.isShow = true
                }
            );
        },
        prepareData() {
            this.message = "";
            this.submitted = true;
            this.bot.pair = this.pair;
            this.bot.user = this.suscripcion.user
            this.bot.quantity = this.quantity;
            this.bot.amount = this.amount;
            this.bot.percent = this.percent;
            this.bot.riesgo = this.riesgo;
            this.bot.client = this.client;
            if (this.bot.gest_riesgo !== '' && this.bot.gest_riesgo !== undefined && this.bot.gest_riesgo !== null) {
                this.bot.gest_riesgo = this.gest_riesgo;
            } else {
                this.bot.gest_riesgo = 0
            }
            if (this.accion === 'new') {
                this.client.id = this.exchange['id']
                this.suscripcion.bot = this.bot
                this.newBot()
            } else {
                this.bot.id = this.idBot
                this.saveBot()
            }
        },
        // saveBot() {
        //     this.message = "";
        //     this.submitted = true;
        //     this.bot.pair = this.pair;
        //     this.bot.user = this.suscripcion.user
        //     this.bot.quantity = this.quantity;
        //     this.bot.amount = this.amount;
        //     this.bot.percent = this.percent;
        //     this.bot.riesgo = this.riesgo;
        //     this.bot.client = this.client;
        //     if (this.bot.gest_riesgo !== '' && this.bot.gest_riesgo !== undefined && this.bot.gest_riesgo !== null) {
        //         this.bot.gest_riesgo = this.gest_riesgo;
        //     } else {
        //         this.bot.gest_riesgo = 0
        //     }
        //     this.suscripcion.bot = this.bot

        //     if (this.idBot !== '' && this.idBot !== undefined) {
        //         // Actualizamos Bot
        //         this.bot.id = this.idBot
        //         UserService.postUpdateBot(this.bot).then(
        //             response => {
        //                 this.content = response.data;
        //                 this.msgError = response
        //                 this.types = 'success'
        //                 this.isShow = true
        //             },
        //             error => {
        //                 this.content =
        //                     (error.response &&
        //                         error.response.data &&
        //                         error.response.data.message) ||
        //                     error.message ||
        //                     error.toString();
        //                 this.msgError = error.response.data
        //                 this.types = 'error'
        //                 this.isShow = true
        //             }
        //         );
        //     } else {
        //         // Añadir bot a Suscripcion
        //         this.client.id = this.exchange['id'];
        //         UserService.postSaveBot(this.suscripcion).then(
        //             response => {
        //                 this.content = response.data;
        //                 this.msgError = response
        //                 this.types = 'success'
        //                 this.isShow = true
        //             },
        //             error => {
        //                 this.content =
        //                     (error.response &&
        //                         error.response.data &&
        //                         error.response.data.message) ||
        //                     error.message ||
        //                     error.toString();
        //                 this.msgError = error.response.data
        //                 // console.log(error.response.data)
        //                 this.types = 'error'
        //                 this.isShow = true
        //             }
        //         );
        //     }
        // },
        newBot() {
            UserService.postSaveBot(this.suscripcion).then(
                response => {
                    this.content = response.data;
                    this.msgError = response
                    this.types = 'success'
                    this.isShow = true
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    // console.log(error.response.data)
                    this.types = 'error'
                    this.isShow = true
                }
            );
        },
        saveBot() {
            UserService.postUpdateBot(this.bot).then(
                response => {
                    this.content = response.data;
                    this.msgError = response
                    this.types = 'success'
                    this.isShow = true
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    this.types = 'error'
                    this.isShow = true
                }
            );
        },
        closeAlert() {
            this.isShow = false
            if (this.types === 'success') {
                this.$router.push('/bot');
            }
        },
        opcionalGestionRiesgo() {
            // console.log("Referido")
            this.optionalShow = !this.optionalShow
        },
    }
}
</script>