<template>
    <AdminBotsComponent/>
  </template>
  
  <script>
  import AdminBotsComponent from '../../components/admin/AdminBotsComponent.vue'
  
  export default {
    name: 'AdminBotsView',
  
    components: {
        AdminBotsComponent,
    },
    
  }
  </script>