<template>
  <LoginComponent />
</template>
  
<script>
import LoginComponent from '../../components/public/LoginComponent.vue'

export default {
  name: 'LoginView',
  components: {
    LoginComponent,
  },
}
</script>
  
  