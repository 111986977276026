<template>
    <transition name="modal-fade" light>
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Wallet copiada en portapapeles." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">{{ msg }}</strong>
                                        </p>
                                        <br>
                                        <v-btn :color=type @click="dialog = false">
                                            ACEPTAR
                                        </v-btn>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>


export default {
    name: 'DialogClipboardComponent',
    data: function () {
        return {
            showModal: true,
            dialog: false,
            msg: '',
            type: 'success'
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },
        closeModal() {
            this.dialog = false
        }
    },
    mounted() {

    }
}
</script>