<template>
    <ClientComponent/>
  </template>
  
  <script>
  import ClientComponent from '../../components/user/ClientComponent.vue'
  
  export default {
    name: 'ClientView',
    components: {
        ClientComponent,
    },
    
  };
  </script>