import { render, staticRenderFns } from "./AdminComercialesView.vue?vue&type=template&id=5b6342e6"
import script from "./AdminComercialesView.vue?vue&type=script&lang=js"
export * from "./AdminComercialesView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports