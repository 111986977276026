<template>
    <AdminComercialSaveComponent />
</template>

<script>
import AdminComercialSaveComponent from '../../components/admin/AdminComercialSaveComponent.vue'

export default {
    name: 'AdminComercialNewView',

    components: {
        AdminComercialSaveComponent,
    },

}
</script>