<template>
    <BotSaveComponent />
</template>
  
<script>
import BotSaveComponent from '../../components/user/BotSaveComponent.vue'

export default {
    name: 'NewBotView',
    components: {
        BotSaveComponent,
    },

};
</script>