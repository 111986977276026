<template>
    <VerifyComponent />
</template>
      
<script>
import VerifyComponent from '../../components/public/VerifyComponent.vue'

export default {
    name: 'VerifyView',
    components: {
        VerifyComponent,
    },
}
</script>