<template>
    <v-container class="w-auto bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col cols="12">
                    <br>
                    <v-row justify="center">
                        <v-card-title class="text-h1">TRADE MASTER BOTS</v-card-title>
                    </v-row>
                    <br>
                    <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
                    <v-img :src="require('../../assets/logo.png')" class="my-3" contain height="200" />
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
import User from '@/models/user';

export default {
    name: 'IndexComponent',
    components: {
        // VideoPlayer
    },
    data() {
        return {
            user: new User()
        };
    },
    mounted() {
        // console.log(this.$route.query)
        if (!this.isEmpty(this.$route.query)) {
            // if (this.$route.query !== null && this.$route.query !== undefined && !this.isEmpty(this.$route.query)) {
            console.log(1)
            let values = Object.values(this.$route.query)
            console.log(values)
            this.user.email = values[0]

            this.user.refreshToken = values[1]
            // this.user.id = values[1]
            this.user.username = values[2]

            this.$store.dispatch("auth/loginBot", this.user).then(
                () => {

                    if (this.$store.state.auth.user !== null && this.$store.state.auth.user !== undefined) {
                        // console.log(this.$store.state.auth.user)
                        console.log(2)
                        // this.$router.push('/home');
                        if (this.$store.state.auth.user.roles[0] === 'ADMIN') {
                            this.$router.push('/admin_home');
                        } else {
                            this.$router.push('/home');
                        }
                    }
                });
        } else {
            // if (this.$store.state.auth.user !== null && this.$store.state.auth.user !== undefined) {
            //     // console.log(this.$store.state.auth.user)
            //     console.log(3)
            //     this.$router.push('/home');
            // } else {
            //     console.log(4)
            //     this.$store.dispatch("auth/logout");
            // }
        }
    },
    computed: {
        videoElement() {
            return this.$refs.video;
        },
        itemsWithIndex() {
            return this.items.map((item, index) => ({ ...item, index: index }))
        }
    },
    created() {

    },
    methods: {
        responseLogin(response, msg) {
            if (response === 'success') {
                console.log(msg)
                // this.$router.go()
                this.$router.push('/home');
            } else {
                console.log(msg)
            }
        },
        isEmpty(obj) {
            for (const prop in obj) {
                if (Object.hasOwn(obj, prop)) {
                    return false;
                }
            }

            return true;
        },
    }
}
</script>