<template>
    <CriptoResultComponent />
</template>

<script>
import CriptoResultComponent from '../../components/cripto/CriptoResultComponent.vue'


export default {
    name: "CriptoResultView",
    components: {
        CriptoResultComponent,
    },
};
</script>

<style lang="scss" scoped></style>