<template>
    <CartComponent />
</template>

<script>
import CartComponent from '../../components/user/CartComponent.vue'


export default {
    name: "BotsView",
    components: {
        CartComponent,
    },
};
</script>

<style lang="scss" scoped></style>