<template>
    <AdminTicketsComponent />
</template>

<script>
import AdminTicketsComponent from '../../components/admin/AdminTicketsComponent.vue'

export default {
    name: 'AdminTicketsView',

    components: {
        AdminTicketsComponent
    },

}
</script>