<template>
  <HomeComponent/>
</template>

<script>
import HomeComponent from '../../components/user/HomeComponent.vue'

export default {
  name: 'HomeView',

  components: {
    HomeComponent,
  },
  
}
</script>
