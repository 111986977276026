import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
    iconfont: 'fa',
  },
	theme: { dark: true }
});
