<template>
    <AdminSubComponent/>
  </template>
  
  <script>
  import AdminSubComponent from '../../components/admin/AdminSubComponent.vue'
  
  export default {
    name: 'AdminSubView',
  
    components: {
        AdminSubComponent,
    },
    
  }
  </script>