<template>
  <BotComponent />
</template>
  
<script>
import BotComponent from '../../components/user/BotComponent.vue'


export default {
  name: "BotsView",
  components: {
    BotComponent,
  },
};
</script>
  
<style lang="scss" scoped></style>
  