<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <div class="d-flex flex-column mb-6 bg-surface-variant">
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col>
                        <v-card elevation="2" outlined shaped>
                            <v-row no-gutters>
                                <v-col cols="2">
                                </v-col>
                                <v-col cols="8">
                                    <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                        <AdminMenu />
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-card-title class="text-h5">NUEVO PAGO</v-card-title>
                            </v-row>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="3" xs="0" sm="0" md="3" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="6" xs="8" sm="8" md="6" class="flex-grow-0 flex-shrink-0">
                                            <div v-if="isNewPay">
                                                <validation-provider v-slot="{ errors }" name="Usuario"
                                                    rules="required">
                                                    <v-text-field v-model="usuario" :error-messages="errors"
                                                        label="Usuario" readonly></v-text-field>
                                                </validation-provider>
                                            </div>
                                            <div v-if="!isNewPay">
                                                <validation-provider v-slot="{ errors }" name="Usuario"
                                                    rules="required">
                                                    <v-select v-model="usuario" :items="itemsUsuarios" item-text="email"
                                                        item-value="id" label="Selecciona usuario" persistent-hint
                                                        return-object single-line></v-select>
                                                    <div class="invalid-feedback">
                                                        <p style="color:red">{{ errors[0] }}</p>
                                                    </div>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Tipo" rules="required">
                                                    <v-select v-model="tipo" :items="itemsTipos" item-text="nombre"
                                                        item-value="id" label="Selecciona tipo" persistent-hint
                                                        return-object single-line @change="returnTipo"></v-select>
                                                    <div class="invalid-feedback">
                                                        <p style="color:red">{{ errors[0] }}</p>
                                                    </div>
                                                    <v-text-field v-model="tipoText" :error-messages="errors"
                                                        label="Tipo" readonly disabled></v-text-field>
                                                </validation-provider>
                                            </div>
                                            <v-card ref="cantidad" :disabled="habilitarDiv">
                                                <v-row justify="center">
                                                    <v-col cols="3">
                                                        <v-btn class="mx-2" fab dark color="success"
                                                            @click="sumarCantidad">
                                                            <v-icon dark>
                                                                mdi-plus
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <validation-provider v-slot="{ errors }" name="cantidad"
                                                            rules="required">
                                                            <v-text-field v-model="cantidad" :error-messages="errors"
                                                                label="Cantidad" required></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-btn class="mx-2" fab dark color="error"
                                                            @click="restarCantidad">
                                                            <v-icon dark>
                                                                mdi-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>


                                            <validation-provider v-slot="{ errors }" name="Fecha_Inicio"
                                                rules="required|date">
                                                <!-- <v-date-picker v-model="fecha_inicio" elevation="15" :error-messages="errors" required></v-date-picker> -->
                                                <v-text-field v-model="fecha_inicio" :counter="10"
                                                    :error-messages="errors" label="Fecha Inicio"
                                                    required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="total" rules="required">
                                                <v-text-field v-model="total" :error-messages="errors" label="Total"
                                                    required readonly disabled></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="metodo" rules="required">
                                                <v-text-field v-model="metodo" :error-messages="errors"
                                                    label="Metodo de Pago" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="referenciaPago">
                                                <!-- rules="required"> -->
                                                <v-text-field v-model="referenciaPago" :error-messages="errors"
                                                    label="referenciaPago" required></v-text-field>
                                            </validation-provider>
                                            
                                            <validation-provider v-slot="{ errors }" name="pagado"
                                                :rules="{ required: { allowFalse: false } }">
                                                <v-row no-gutters style="flex-wrap: nowrap;">
                                                    <label class="checkbox" style="margin-top: 0px;margin-right: 15px;">
                                                        <input v-model="pagado" name="pagado" type="checkbox"
                                                            :error-messages="errors">
                                                    </label>
                                                    <p style="margin-top: 3px;">
                                                        Pagado</p>
                                                </v-row>

                                                <!-- <a href="https://www.trademasterbots.com/terms" target="_blank">Terminos y Condiciones</a> -->
                                            </validation-provider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="5" xs="0" sm="0" md="5">
                                        </v-col>
                                        <v-col cols="2" xs="2" sm="2" md="2">
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success" @click="handleRegister">
                                                    GUARDAR
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                    <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                    <v-col cols="10" xs="8" sm="8" md="4">
                        <v-alert prominent :type="types">
                            <v-row>
                                <v-col class="grow">
                                    {{ msgError }}
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="closeDialog">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
import AdminMenu from "../../components/admin/AdminMenuComponent.vue";
import EventBus from "../../common/EventBus";
import User from '../../models/user';
import Type from '../../models/type';
import Pago from '../../models/pay';
import PagoRequest from '../../models/pagoRequest';
import UserService from '../../services/user.service'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend("date", {
    params: ['otherValue'],
    validate: (value) => {
        if (
            value === null ||
            value.length < 10
            ||
            !/^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La fecha no es correcta."
});

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

extend("pass", {
    params: ["otherValue"],
    validate: (value) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#!@#]).+$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no cumple los requisitos."
});

extend("confirmed", {
    params: ['otherValue'],
    validate: (value, { otherValue }) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#!@#]).+$/.test(value) ||
            value !== otherValue
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no coincide."
});

export default {
    name: 'AdminPagosSaveComponent',
    components: {
        AdminMenu,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            idComercial: '',
            user: new User('', ''),
            type: new Type('', ''),
            pago: new Pago('', ''),
            pagoRequest: new PagoRequest('', ''),
            nombre: '',
            itemsUsuarios: [],
            usuario: '',
            itemsTipos: [],
            tipo: '',
            tipoText: '',
            cantidad: 1,
            fecha_inicio: '',
            fecha_fin: '',
            pagado: '',
            total: '',
            metodo: '',
            referenciaPago: '',
            habilitarDiv: false,

            referido: '',
            param_refer: '',
            show4: false,
            show5: false,
            password1: '',
            submitted: false,
            successful: false,
            message: '',
            msgError: '',
            isShow: false,
            types: "success",
            success: false,
            errors: ''
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isNewPay() {
            if (localStorage.adminComercial !== '' && localStorage.adminComercial !== undefined) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        if (this.$route.params.idComercial !== null) {
            this.idComercial = this.$route.params.idComercial
        }

        if (this.idComercial !== '' && this.idComercial !== undefined) {
            // console.log(this.idComercial)
            UserService.getAdminComercial(this.idComercial).then(
                response => {
                    this.content = response.data
                    // console.log(this.content)
                    this.comercial.id = this.content.id
                    this.comercial.nombre = this.content.nombre
                    this.comercial.codigoReferido = this.content.codigoReferido
                    this.usuario = this.comercial.nombre
                    this.referido = this.comercial.codigoReferido
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        } else {
            UserService.getUsers().then(
                response => {
                    this.itemsUsuarios = response.data
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );

            UserService.getTypes().then(
                response => {
                    console.log(response)
                    this.itemsTipos = response.data
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }

    },
    methods: {
        returnTipo(value) {
            // console.log(value)
            this.tipoText = value.descripcion
            this.total = value.precio * this.cantidad
            
        },
        // habilitarDiv(value) {
        //     return value;
        // },
        sumarCantidad() {
            this.cantidad++
            this.total = this.tipo.precio * this.cantidad
        },
        restarCantidad() {
            if (this.cantidad > 1) {
                this.cantidad--
                this.total = this.tipo.precio * this.cantidad
            }

        },
        validate() {
            console.log(this.$refs.form.validate())
        },
        validateDate(date) {
            let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])[/]\d{4}$/;
            // /^ (0 ? [1 - 9] | 1[0 - 2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;

            // Matching the date through regular expression      
            if (date.match(dateformat)) {
                let operator = date.split('/');

                // Extract the string into month, date and year      
                let datepart = [];
                if (operator.length > 1) {
                    datepart = date.split('/');
                }

                let day = parseInt(datepart[0]);
                let month = parseInt(datepart[1]);
                let year = parseInt(datepart[2]);

                // Create a list of days of a month      
                let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                if (month == 1 || month > 2) {
                    if (day > ListofDays[month - 1]) {
                        //to check if the date is out of range     
                        return false;
                    }
                } else if (month == 2) {
                    let leapYear = false;
                    if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
                    if ((leapYear == false) && (day >= 29)) return false;
                    else
                        if ((leapYear == true) && (day > 29)) {
                            console.log('Invalid date format!');
                            return false;
                        }
                }
            } else {
                console.log("Invalid date format!");
                return false;
            }
            return true
        },
        handleRegister() {
            this.message = '';
            this.submitted = true;
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                }
                // Resetting Values
                // this.firstName = this.lastName = this.email = '';

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    try {
                        // if (this.idComercial !== '' && this.idComercial !== undefined) {
                        //     this.comercial.nombre = this.usuario
                        // } else {
                        //     this.comercial.nombre = this.usuario.email
                        // }

                        // this.id = id
                        this.pagoRequest.user = this.usuario
                        this.pagoRequest.tipo_suscripcion = this.tipo
                        this.pagoRequest.fecha_inicio = this.fecha_inicio
                        this.pagoRequest.metodo_pago = this.metodo
                        this.pagoRequest.payed = this.pagado
                        this.pagoRequest.cantidad = this.cantidad
                        
                        this.user = this.usuario
                        this.type = this.tipo

                        this.pago.user = this.usuario
                        this.pago.quantity = this.total;
                        this.pago.start_time = this.fecha_inicio;
                        this.pago.end_time = this.fecha_fin;
                        this.pago.payed = this.pagado;
                        this.pago.chargeID = this.chargeID
                        this.pago.chargePermissionID = this.chargePermissionID
                        this.pago.metodo = this.metodo

                        UserService.savePagoAdmin(this.pagoRequest).then(
                            response => {
                                console.log(response)
                                if (response.status === 200) {
                                    this.msgError = 'Pago guardado correctamente';
                                    this.types = 'success'
                                    this.successful = true;
                                    this.isShow = true
                                } else {
                                    // console.log(response.response.data.message)
                                    this.msgError = response.response.data.message;
                                    this.types = 'error'
                                    this.successful = false;
                                    this.isShow = true
                                }
                            },
                            error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                // console.log(error.response)
                                this.error = true
                                this.msgError = error
                                this.types = 'error'
                                this.isShow = true
                                this.successful = false;
                            }
                        );
                    } catch (error) {
                        // console.log(error)
                        this.error = true
                        this.msgError = error
                        this.types = 'error'
                        this.isShow = true
                    }
                });
            });
        },
        // goToIndex() {
        //     this.$router.push('/');
        // },
        // clickDialog() {
        //     if (this.successful) {
        //         this.isShow = false
        //         this.$router.push('/login');
        //     } else {
        //         this.isShow = false
        //     }
        // },
        closeDialog() {
            if (this.successful) {
                this.isShow = false
                this.$router.push('/admin_pagos');
            } else {
                this.isShow = false
            }
        }
    }
}
</script>

<style scoped>
.login {
    padding: 2rem;
}

.title {
    text-align: center;
}

.form {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    min-width: 350px;
    max-width: 100%;
    background: rgba(19, 35, 47, 0.9);
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}


label {
    display: block;
    margin-top: 10px;
}


p {
    line-height: 1rem;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>