<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ titleOrders }}</v-card-title>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="0" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="12" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card elevation="2" outlined shaped>
                                    <br>
                                    <v-row no-gutters>

                                        <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-list-item-title class="text-center">Fecha
                                                        Inicio:</v-list-item-title>
                                                    <v-list-item-title class="text-center">{{ initDate
                                                        }}</v-list-item-title>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-btn :color="colors" @click="statusBot" :disabled="isAdmin">{{ msgBtn }}</v-btn>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                    <vDivider></vDivider>
                                    <br>
                                    <v-row no-gutters>
                                        <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-list-item-title class="text-center">Precio
                                                        Actual:</v-list-item-title>
                                                    <v-list-item-title class="text-center">{{ currentPrice
                                                        }}</v-list-item-title>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-list-item-title class="text-center">Saldo
                                                        Bot:</v-list-item-title>
                                                    <v-list-item-title class="text-center">{{ available
                                                        }}</v-list-item-title>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                    <vDivider></vDivider>
                                    <br>
                                    <v-row no-gutters>
                                        <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-list-item-title
                                                        class="text-center">Beneficios:</v-list-item-title>
                                                    <v-list-item-title class="text-center">{{ profits
                                                        }}</v-list-item-title>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <v-list-item-title
                                                        class="text-center">Comisiones:</v-list-item-title>
                                                    <v-list-item-title class="text-center">{{ fees
                                                        }}</v-list-item-title>
                                                </v-card>
                                            </v-row>

                                        </v-col>
                                    </v-row>
                                    <br>
                                    <vDivider></vDivider>
                                    <br>
                                    <v-row no-gutters>
                                        <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0"></v-col>
                                        <v-col cols="10" xs="0" sm="0" md="10" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters justify="center">
                                                <v-card>
                                                    <ChartComponent ref="chart"/>
                                                </v-card>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <br>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import Bot from "../../models/bot";
import UserService from '../../services/user.service';
// import EventBus from "../../common/EventBus";
import ChartComponent from '../../components/utils/ChartComponent.vue'

export default {
    name: 'OrdersDataComponent',
    components: {
        ChartComponent
    },
    data() {
        return {
            idBot: '',
            bot: new Bot(),
            titleOrders: "",
            pair1: "",
            pair2: "",
            initDate: "",
            available: '',
            startPrice: "",
            currentPrice: "",
            profits: "",
            fees: "",
            right: null,
            start: false,
            colors: "success",
            msgBtn: '',
            isAdmin: false
        };
    },
    computed: {
        itemsWithIndex() {
            return this.items.map((item, index) => ({ ...item, index: index }))
        }
    },
    created() {
    },
    mounted() {
        // this.loadChart()
        // if (this.$route.params.idBot !== null) {
        //     this.idBot = this.$route.params.idBot
        //     UserService.getStatusBot(this.idBot).then(
        //         response => {
        //             // console.log(response.data)
        //             if (response.data === 'NOT INIT') {
        //                 UserService.getBot(this.idBot).then(
        //                     response => {
        //                         this.bot = response.data
        //                         this.start = false
        //                         this.msgBtn = 'START'
        //                         this.colors = 'success'
        //                     }
        //                 );

        //             } else {
        //                 this.loadData(response.data);
        //             }

        //         },
        //         error => {
        //             this.content =
        //                 (error.response && error.response.data && error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //             if (error.response && error.response.status === 403) {
        //                 EventBus.dispatch("logout");
        //             }
        //         }
        //     );
        // }

    },
    methods: {
        initData(value, isAdmin) {
            this.idBot = value  
            this.isAdmin = isAdmin
            UserService.getStatusBot(this.idBot).then(
                response => {
                    if (response.data === 'NOT INIT') {
                        UserService.getBot(this.idBot).then(
                            response => {
                                this.bot = response.data
                                this.start = false
                                this.msgBtn = 'START'
                                this.colors = 'success'
                            }
                        );

                    } else {
                        this.loadData(response.data, isAdmin);
                    }

                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        // EventBus.dispatch("logout");
                    }
                }
            );
        },
        loadData(data, isAdmin) {
            if (data !== 'NOT INIT') {
                this.bot = data.bot;
                this.$refs.chart.loadDataSets(this.bot.id, isAdmin)
                this.titleOrders = this.bot.pair;
                let arrayPairs = this.bot.pair.split("-");
                this.pair1 = arrayPairs[0];
                this.pair2 = arrayPairs[1];
                this.initDate = data.start_time
                this.available = data.available
                this.startPrice = data.start_price
                this.currentPrice = data.currentPrice
                this.fees = data.fees
                this.profits = data.profits
                if (this.bot.started === false) {
                    this.start = false
                    this.msgBtn = 'START'
                    this.colors = 'success'
                } else {
                    this.start = true
                    this.msgBtn = 'STOP'
                    this.colors = 'error'
                }
            } else {
                this.start = false
                this.msgBtn = 'START'
                this.colors = 'success'
            }
        },
        statusBot() {
            if (this.start === false) {
                this.start = true
                this.msgBtn = 'STOP'
                this.colors = 'error'
                // Iniciar bot
                this.sendStatus(true)
            } else {
                this.start = false
                this.msgBtn = 'START'
                this.colors = 'success'
                // Parar bot
                this.pauseBot(false)
            }
        },
        sendStatus(status) {
            this.message = '';
            this.submitted = true;
            this.bot.started = status
            UserService.postStatusBot(this.bot).then(
                response => {
                    this.loadData(response.data)
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response
                    this.types = 'error'
                    this.isShow = true
                }
            );
        },
        pauseBot(status) {
            this.message = '';
            this.submitted = true;
            this.bot.started = status
            UserService.pauseBot(this.bot).then(
                response => {
                    this.loadData(response.data)
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response
                    this.types = 'error'
                    this.isShow = true
                }
            );
        }
    }
}
</script>

<style scoped>
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
    background-color: blue;
}

.compraClass tbody tr {
    background-color: red !important;
    color: red;
}

.ventaClass {
    background-color: green
}
</style>