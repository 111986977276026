<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet v-for="n in 1" :key="n" class="ma-2 pa-2">
                <v-card elevation="2" outlined shaped>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <AdminMenu />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <v-card-title>{{ title }}</v-card-title>
                                <v-divider class="mx-4"></v-divider>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card class="pa-2">
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-row no-gutters style="flex-wrap: nowrap;">
                                            <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                                <validation-provider v-slot="{ errors }" name="Nombre"
                                                    rules="required|max:20">
                                                    <v-text-field v-model="nombre" :counter="20"
                                                        :error-messages="errors" label="Nombre" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Descripcion"
                                                    rules="required|max:250">
                                                    <v-text-field v-model="descripcion" :counter="250"
                                                        :error-messages="errors" label="Descripcion"
                                                        required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Dias"
                                                    rules="required|max:20">
                                                    <v-text-field v-model="dias" :counter="2" :error-messages="errors"
                                                        label="Dias" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Limite"
                                                    rules="required|max:10">
                                                    <v-text-field v-model="limite" :counter="10"
                                                        :error-messages="errors" label="Limite" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Precio"
                                                    rules="required|max:8">
                                                    <v-text-field v-model="precio" :counter="5" :error-messages="errors"
                                                        label="Precio" required></v-text-field>
                                                </validation-provider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters style="flex-wrap: nowrap;">
                                            <v-col cols="4" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                                <!-- <v-spacer></v-spacer> -->
                                                <v-btn :disabled="invalid" color="success" class="mr-4"
                                                    @click="saveTipoSuscripcion">
                                                    Guardar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-col>
                    </v-row>
                    <br>
                    <v-divider class="mx-4" horizontal></v-divider>
                    <br>
                    <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                        <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                        <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                            <v-alert prominent :type="types">
                                <v-row>
                                    <v-col class="grow">
                                        {{ msgError }}
                                    </v-col>
                                    <v-col class="shrink">
                                        <v-btn @click="closeAlert">OK</v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
// import EventBus from "../../common/EventBus";
import AdminMenu from "../../components/admin/AdminMenuComponent.vue";
import UserService from '../../services/user.service';
import TipoSuscripcion from '../../models/tipoSuscripcion';
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

export default {
    name: 'AdminSubComponent',
    components: {
        AdminMenu,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            title: 'SUSCRIPCION',
            idSuscripcion: '',
            tipoSuscripcion: new TipoSuscripcion('', '', ''),
            content: '',
            nombre: '',
            descripcion: '',
            limite: '',
            dias: '',
            precio: '',
            submitted: false,
            message: '',
            valid: '',
            isShow: false,
            msgError: '',
            types: "success"
        };
    },
    computed: {},
    mounted() {
        if (this.$route.params.idSub !== null) {
            this.idSuscripcion = this.$route.params.idSub
        }
        if (this.idSuscripcion !== '0') {
            UserService.getAdminTipoSuscripcion(this.idSuscripcion).then(
                response => {
                    // console.log(response.data)
                    this.content = response.data
                    this.nombre = this.content.nombre
                    this.descripcion = this.content.descripcion
                    this.limite = this.content.limiteInversionPorBot
                    this.dias = this.content.dias
                    this.precio = this.content.precio
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // EventBus.dispatch("logout");
                }
            );
        }

    },
    methods: {
        async saveTipoSuscripcion() {

            this.$refs.observer[0].validate().then(success => {
                if (!success) {
                    // console.log('this.name')
                    return;
                }

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    try {
                        this.message = '';
                        this.submitted = true;
                        if (this.idSuscripcion !== '' && this.idSuscripcion !== undefined) {
                            this.tipoSuscripcion.id = this.idSuscripcion
                        }

                        this.tipoSuscripcion.nombre = this.nombre
                        this.tipoSuscripcion.descripcion = this.descripcion
                        this.tipoSuscripcion.limiteInversionPorBot = this.limite
                        this.tipoSuscripcion.dias = this.dias
                        this.tipoSuscripcion.precio = this.precio
                        // console.log(this.tipoSuscripcion)
                        UserService.saveAdminTipoSuscripcion(this.tipoSuscripcion).then(
                            response => {
                                // console.log(response.email)
                                this.content = response
                                this.nombre = this.content.nombre
                                this.descripcion = this.content.descripcion
                                this.limite = this.content.limiteInversionPorBot
                                this.dias = this.content.dias
                                this.precio = this.content.precio
                                this.msgError = 'Datos guardados correctamente.'
                                this.types = 'success'
                                this.isShow = true
                            },
                            error => {
                                this.content =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.msgError = error
                                // console.log(error.response.data)
                                this.types = 'error'
                                this.isShow = true
                            }
                        );
                    } catch (error) {
                        this.error = true
                        this.msgError = error.response
                        // console.log(error)
                        this.types = 'error'
                        this.isShow = true
                    }
                });
            });
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>