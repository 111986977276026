<template>
    <v-card elevation="2" outlined shaped>
        <Bar id="chart-beneficios" v-if="loaded" :data="chartData" :style="myStyles" />
    </v-card>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import UserService from '../../services/user.service';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'ChartComponent',
    components: {
        Bar
    },
    data() {
        return {
            loaded: true,
            totalBeneficios: '',
            chartData: {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril',
                    'Mayo', 'Junio', 'Julio', 'Agosto',
                    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: [{
                    label: 'Ganancias Mensuales',
                    data: [],
                    backgroundColor: '#ffffff',
                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    computed: {
        myStyles() {
            return {
                height: '${/* mutable height */}px',
                position: 'relative'
            }
        },
    },
    created() { },
    methods: {
        loadDataSets(value, isAdmin) {
            this.loaded = false
            
            if (!isAdmin) {
                UserService.getChartBot(value).then(
                    response => {
                        this.totalBeneficios = response.data;
                        for (let index = 0; index < this.totalBeneficios.length; index++) {
                            this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
                        }
                        this.loaded = true
                    },
                    error => {
                        this.content =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        // EventBus.dispatch("logout");
                    }
                );
            } else {
                UserService.getAdminChartBot(value).then(
                    response => {
                        this.totalBeneficios = response.data;
                        for (let index = 0; index < this.totalBeneficios.length; index++) {
                            this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
                        }
                        this.loaded = true
                    },
                    error => {
                        this.content =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        // EventBus.dispatch("logout");
                    }
                );
            }

        }
    }
}
</script>