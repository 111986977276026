<template>
    <AdminTradesComponent />
</template>

<script>
import AdminTradesComponent from '../../components/admin/AdminTradesComponent.vue'

export default {
    name: 'AdminTradesView',

    components: {
        AdminTradesComponent,
    },

}
</script>