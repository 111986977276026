<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Pagar Suscripción." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PAGA CON AMAZON</strong>
                                        </p>
                                        <br>
                                        <v-row justify="center">
                                            <v-col cols="5" xs="5" sm="5" md="5"
                                                class="flex-grow-0 flex-shrink-0"></v-col>
                                            <v-col cols="2" xs="2" sm="2" md="2" class="flex-grow-0 flex-shrink-0">
                                                <v-text-field class="text-center" @change="onChange($event)">{{
                                                    this.cantidad }}</v-text-field>
                                            </v-col>
                                            <v-col cols="5" xs="5" sm="5" md="5"
                                                class="flex-grow-0 flex-shrink-0"></v-col>
                                        </v-row>
                                        <br>
                                        <v-card class="pa-2">
                                            <v-data-table :headers="headers" :items="items" hide-default-footer>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-icon small class="mr-2"
                                                        @click="handleClick(item, 'pay')">mdi-play</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PRECIO: {{ this.total }} $</strong>
                                        </p>
                                        <br>
                                        <!-- <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">NIVEL: {{ this.tipo }}</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">BOTS: {{ this.bots }}</strong>
                                        </p>
                                        <br>
                                        <div v-if="this.meses < 2">
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MES</strong>
                                            </p>
                                        </div>
                                        <div v-else>
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MESES</strong>
                                            </p>
                                        </div>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PRECIO: {{ this.precio }} €</strong>
                                        </p> -->
                                        <br>
                                        <div id="AmazonPayButton"></div>

                                    </v-card-text>

                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>
// import * as Amazon from '@/services/config.js'
const Amazon = require('@/services/config.js')
import UserService from '../../services/user.service'
import PagoRequest from '../../models/pagoRequest'

export default {
    name: 'PayModalComponent',
    data: function () {
        return {
            showModal: true,
            pagoRequest: new PagoRequest('', ''),
            idTipoSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',
            cantidad: 1,
            total: '',
            headers: [
                { text: "Nombre", value: "nombre", align: "center" },
                { text: "Dias", value: "dias", align: "center" },
                { text: "Limite Bot", value: "limiteInversionPorBot", align: "center" },
                { text: "Precio / $", value: "precio", align: "center" },
                // { text: 'Actions', value: 'actions', sortable: false, align: "center" }
            ],
            items: [],
            item: '',

            dialog: false,
            advertising: true,
            performance: true,
            cookieDuration: 30,
            cookieValue: 'accepted',
            cookieConsent: false
        }
    },
    computed: {

    },
    methods: {
        openModal(value) {
            this.item = value
            this.dialog = true
            this.total = this.precio
            this.items.push(value)
        },
        onChange(value) {
            // console.log(this.item)
            // console.log(value)
            this.total = Math.round((this.precio * value) * 100) / 100
            this.cantidad = value
            // console.log(this.exchange.exchange)
        },
        chargeButton() {
            window.amazon.Pay.renderButton('#AmazonPayButton', {
                // set checkout environment
                merchantId: Amazon.default.amazonPay.merchantId,
                createCheckoutSession: {
                    // url: 'http://localhost:8081/api/auth/createcheckoutsession?idSuscripcion=' + this.idSuscripcion
                    url: Amazon.default.amazonPay.endpoint.CreateCheckOut + this.idTipoSuscripcion + '&cantidad=' + this.cantidad
                    // url: UserService.getTypes()
                    // url: this.createCheckout()
                },
                sandbox: Amazon.default.amazonPay.sandbox, // dev environment
                ledgerCurrency: Amazon.default.amazonPay.ledgerCurrency, // Amazon Pay account ledger currency
                checkoutLanguage: Amazon.default.amazonPay.checkoutLanguage, // render language
                productType: Amazon.default.amazonPay.productType, // checkout type
                placement: Amazon.default.amazonPay.placement, // button placement
                buttonColor: Amazon.default.amazonPay.buttonColor
            });
        },
        createCheckout() {
        UserService.cryptoPay().then(
            response => {
                console.log(response)
                // this.itemsTipos = response.data
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                // if (error.response && error.response.status === 403) {
                //     EventBus.dispatch("logout");
                // }
            }
        );
    }
    },
    
    mounted() {
        // console.log(Amazon.default)
    }
}
</script>